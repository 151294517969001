import React, { Component } from 'react'
import { withStyles, Card, CardContent, TextField, Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search'
import { PostData } from './../../../api/service';
import PreviewFrom from './PreviewFrom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ChoiceFillUp from './ChoiceFillUp';
import Snack from './../../Snackbar/Snack'


const styles = theme => ({
    buttonSearch: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2
    }
})

class NewAdmission extends Component {

    state = {
        form_id: '',
        isLoaded: false,
        active: 0,
        type: '',
        email: '',
        open: false,
        message: '',
        receipt_no: '1'
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoaded: false
        })

        let d = {
            form_id: this.state.form_id
        }

        PostData(`/admissionform/getactivestudentsinglebyformid`, d)
            .then((resp) => {
                //console.log(resp);
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        email: resp[0].email,
                        type: resp[0].admission_type,
                        isLoaded: true
                    })
                } else {
                    confirmAlert({
                        title: 'No data found',
                        message: '',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.setState({ form_id: '', email: '' })
                            }
                        ]
                    });
                }

            })


    }

    nextStep = () => {
        this.setState({
            active: this.state.active + 1
        })
    }

    setData = (data) => {
        let d = {
            final_major: data,
            email: this.state.email,
            course: this.state.data.course
        }

        //console.log(d)

        if (this.state.data.course === 'TDC') {
            PostData(`/admissionform/addfinalmajor`, d)
                .then((resp) => {

                    this.setState({
                        open: true,
                        message: resp.message
                    }, () => this.nextStep())
                })
        } else {
            this.nextStep();
        }


    }

    setDataTwo = (data) => {
        let d = {
            ...data,
            form_id: this.state.data.form_id
        }

        //console.log(d)


        PostData(`/${this.props.apikey}/addadmissionreceipt`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    open: true,
                    message: resp.message,
                    receipt_no: resp.receipt_no
                }, () => this.nextStep())
            })
    }
    render() {
        let { classes } = this.props;

        let i = '';

        if (this.state.isLoaded) {
            if (this.state.active === 0) {
                i = <div>
                    <PreviewFrom
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        nextClick={this.nextStep}
                        type={this.state.type}
                    />
                </div>
            } else if (this.state.active === 1) {
                i = <div>
                    
                    <ChoiceFillUp
                        data={this.state.data}
                        isLoaded={this.state.isLoaded}
                        email={this.state.email}
                        setData={this.setData.bind(this)}
                        type={this.state.type}
                    />
                </div>
            } 
        }
        return (
            <div>

                {this.state.open ?
                    (
                        <div>

                            <Snack
                                open={this.state.open}
                                message={this.state.message}
                                handleClose={() => {
                                    this.setState({
                                        open: false,
                                        message: ''
                                    })
                                }}
                            />

                        </div>
                    )
                    : null
                }
                <Card>
                    <CardContent style={{ textAlign: 'center' }}>
                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Form No."
                                type="number"
                                min="0"
                                name="form_id"
                                value={this.state.form_id}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <Button
                                type="submit"
                                className={classes.buttonSearch}
                                color="primary"
                            >
                                <Search />
                            </Button>


                        </form>
                    </CardContent>
                </Card>

                <br />

                {this.state.isLoaded ? i : null}
            </div>
        )
    }
}

export default withStyles(styles)(NewAdmission)