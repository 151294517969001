import React, { Component } from 'react'
import { withStyles, Card, CardContent, TextField, Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search'
import { PostData } from './../../../api/service';
import PreviewFrom from './PreviewFrom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ChoiceFillUp from './ChoiceFillUp';
import Snack from './../../Snackbar/Snack'
import EditMarks from './EditMarks';


const styles = theme => ({
    buttonSearch: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2
    }
})

class EditAdmission extends Component {

    state = {
        form_id: '',
        isLoaded: false,
        active: 0,
        email: '',
        open: false,
        message: '',
        receipt_no: '1'
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoaded: false
        })

        let d = {
            form_id: this.state.form_id
        }

        PostData(`/admissionform/getactivestudentsinglebyformid`, d)
            .then((resp) => {
                //console.log(resp);
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        email: resp[0].email,
                        isLoaded: true
                    })
                } else {
                    confirmAlert({
                        title: 'No data found',
                        message: '',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.setState({ form_id: '', email: '' })
                            }
                        ]
                    });
                }

            })


    }

    setData = (data) => {
        console.log(data)

        PostData(`/admissionform/adminedit`, data)
        .then((resp) => {
            //console.log(resp);
            this.setState({
                open: true,
                message: resp
            }, () => {
                window.location.reload();
            })
        })
    }

    
    

    
    render() {
        let { classes } = this.props;

        let i = '';

        
        return (
            <div>

                {this.state.open ?
                    (
                        <div>

                            <Snack
                                open={this.state.open}
                                message={this.state.message}
                                handleClose={() => {
                                    this.setState({
                                        open: false,
                                        message: ''
                                    })
                                }}
                            />

                        </div>
                    )
                    : null
                }
                <Card>
                    <CardContent style={{ textAlign: 'center' }}>
                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Form No."
                                type="number"
                                min="0"
                                name="form_id"
                                value={this.state.form_id}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <Button
                                type="submit"
                                className={classes.buttonSearch}
                                color="primary"
                            >
                                <Search />
                            </Button>


                        </form>
                    </CardContent>
                </Card>

                <br />

                {this.state.isLoaded ? <EditMarks 
                    setData={this.setData.bind(this)}
                data={this.state.data} /> : null}

               
            </div>
        )
    }
}

export default withStyles(styles)(EditAdmission)