import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid } from '@material-ui/core';
import { PostData, GetData } from '../../../api/service';
import ReactToPrint from 'react-to-print';

const styles = theme => ({
    card: {
        width: '3.725in',
        height: '100%',
        backgroundColor: 'white',
        
    },
    nameFont: {
        fontSize: '0.6em',
        padding: 0,
        fontWeight: 600
    },
    downFont: {
        fontSize: '0.5em'
    },
    imageContainer: {
        width: '70px',
        height: '90px',
        marginLeft: '10px',
        
    },
    sigContainer: {
        width: '70px',
        height: '20px',
        border: 'solid thin lightgrey',
        marginLeft: '10px',
    },
    headerStyle: {
        backgroundColor: '#1357B3',
    },
    titleText: {
        fontWeight: 500,
        color: 'white'
    },
    titleTextCap: {
        fontSize: '0.5em',
        color: 'white'
    },
    titleTextTop: {
        paddingTop: '2px',
        fontSize: '0.8em',
        color: 'white'
    },
    gridStart: {
        paddingTop: '3px',
    },
    sessionBox: {
        textalign: 'right',
        /* backgroundColor: '#1357B3',
        borderBottomLeftRadius: '30px' */
    },
    sessionText: {
        fontSize: '0.6em',
        paddingRight: '5px',
        fontWeight: 'bold'
    },
    idFont: {
        fontSize: '0.8em',
        fontWeight: 'bold'
    }
})

class IdCardHolder extends Component {
    state = {
        data: {},
        isLoaded: false,
    }

    buttonRef = React.createRef();

    componentDidMount() {
        let d = {
            id: this.props.id
        }

        PostData(`/admission/idcards`, d)
            .then((resp) => {
                //console.log(resp)

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }

            })
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                {this.state.isLoaded ? (
                    <div>
                        <IdCard
                            {...this.state.data}
                            {...this.props}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}
export default withStyles(styles)(IdCardHolder);

class IdCard extends Component {

    determineClass(data){
        let c = '';

    

        if(data.course == 'HS' && data.stream == 'ARTS' && data.semester == '1'){
            c = 'HS 1st Year ARTS';
        } else if (data.course == 'HS' && data.stream == 'ARTS' && data.semester == '2'){
            c = 'HS 2nd Year ARTS';
        } else if (data.course == 'HS' && data.stream == 'SCIENCE' && data.semester == '1') {
            c = 'HS 1st Year SCIENCE';
        } else if (data.course == 'HS' && data.stream == 'SCIENCE' && data.semester == '2') {
            c = 'HS 2nd Year SCIENCE';
        } else if (data.course == 'HS' && data.stream == 'COMMERCE' && data.semester == '1') {
            c = 'HS 1st Year COMMERCE';
        } else if (data.course == 'HS' && data.stream == 'COMMERCE' && data.semester == '2') {
            c = 'HS 2nd Year COMMERCE';
        } else if (data.course == 'TDC' && data.stream == 'ARTS' && (data.semester == '1' || data.semester == '2')) {
            c = 'BA 1st & 2nd Semester';
        } else if (data.course == 'TDC' && data.stream == 'ARTS' && (data.semester == '3' || data.semester == '4')) {
            c = 'BA 3rd & 4th Semester';
        } else if (data.course == 'TDC' && data.stream == 'ARTS' && (data.semester == '5' || data.semester == '6')) {
            c = 'BA 5th & 6th Semester';
        } else if (data.course == 'TDC' && data.stream == 'SCIENCE' && (data.semester == '1' || data.semester == '2')) {
            c = 'BSc 1st & 2nd Semester';
        } else if (data.course == 'TDC' && data.stream == 'SCIENCE' && (data.semester == '3' || data.semester == '3')) {
            c = 'BSc 3rd & 4th Semester';
        } else if (data.course == 'TDC' && data.stream == 'SCIENCE' && (data.semester == '5' || data.semester == '6')) {
            c = 'BSc 5th & 6th Semester';
        } else if (data.course == 'TDC' && data.stream == 'COMMERCE' && (data.semester == '1' || data.semester == '2')) {
            c = 'BCom 1st & 2nd Semester';
        } else if (data.course == 'TDC' && data.stream == 'COMMERCE' && (data.semester == '3' || data.semester == '4')) {
            c = 'BCom 3rd & 4th Semester';
        } else if (data.course == 'TDC' && data.stream == 'COMMERCE' && (data.semester == '5' || data.semester == '6')) {
            c = 'BCom 5th & 6th Semester';
        } else if (data.course == 'BCA' && (data.semester == '1' || data.semester == '2')) {
            c = 'BCA 1st & 2nd Semester';
        } else if (data.course == 'BCA' && (data.semester == '3' || data.semester == '4')) {
            c = 'BCA 3rd & 4th Semester';
        } else if (data.course == 'BCA' && (data.semester == '5' || data.semester == '6')) {
            c = 'BCA 5th & 6th Semester';
        } else if (data.course == 'BSC-BIOTECHNOLOGY' && (data.semester == '1' || data.semester == '2')) {
            c = 'BIOTECH 1st & 2nd Semester';
        } else if (data.course == 'BSC-BIOTECHNOLOGY' && (data.semester == '3' || data.semester == '4')) {
            c = 'BIOTECH 3rd & 4th Semester';
        } else if (data.course == 'BSC-BIOTECHNOLOGY' && (data.semester == '5' || data.semester == '6')) {
            c = 'BIOTECH 5th & 6th Semester';
        } else if (data.course == 'PG' && (data.semester == '1' || data.semester == '1')) {
            c = 'PG 1st & 2nd Semester';
        } else if (data.course == 'PG' && (data.semester == '3' || data.semester == '4')) {
            c = 'PG 3rd & 4th Semester';
        }

        return c;

    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.card}>
                <style type="text/css">
                    {"@media print{@page {size: landscape}}"}
                </style>
                <table width="100%" className={classes.headerStyle}>
                    <tbody>
                        <tr>
                            <td width="20%" align="center">
                                <img
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '80%' }}
                                />
                            </td>
                            <td align="center" width="60%">
                                <Typography
                                    
                                    className={classes.titleText}
                                >
                                    PANDU COLLEGE
                                </Typography>
                                <Typography
                                    
                                    className={classes.titleTextCap}

                                >
                                   P.O: Pandu, Guwahati,Assam Pin: 781 012
                                </Typography>
                                <Typography
                                    className={classes.titleTextCap}
                                >
                                    Contact No.: 0361 2570450
                                </Typography>
                                <Typography
                                    className={classes.titleTextCap}
                                >
                                    www:panducollege.org | Email: info@panducollege.org
                                </Typography>
                                
                            </td>
                            <td width="20%">

                            </td>
                        </tr>
                    </tbody>
                </table>
                <Grid container>
                    <Grid item lg={4} sm={4} xs={4} md={4}>
                       &nbsp;
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} md={4}>
                        <Typography
                            align="center"
                            className={classes.idFont}
                        >
                            IDENTITY CARD
                            </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} md={4} className={classes.sessionBox}>
                        {/* <Typography
                            align="right"
                            className={classes.sessionText}
                        >
                            Session: 2019-20
                            </Typography> */}

                            &nbsp;
                    </Grid>
                    <Grid item lg={3} sm={3} xs={3} md={3}>
                        <div className={classes.imageContainer}>
                            <ImageViewer
                                data={this.props.dp}
                            />
                        </div>

                        {/* <div className={classes.sigContainer}>
                            <ImageViewer
                                data={this.props.signature}
                            />
                        </div> */}
                    </Grid>
                    <Grid item lg={9} sm={9} xs={9} md={9}>
                        <table width="100%" style={{ borderCollapse: "collapse", marginLeft: '10px' }}>
                            <tbody>
                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td width="25%">
                                        <Typography className={classes.nameFont}>
                                            Name
                                        </Typography>
                                    </td>
                                    <td width="3%">
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td width="72%">
                                        <Typography className={classes.nameFont}>
                                            {this.props.name}
                                        </Typography>
                                    </td>
                                </tr>

                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            Course
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            {this.determineClass(this.props)}
                                            
                                        </Typography>
                                    </td>
                                </tr>

                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            Roll No
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            {this.props.roll_no}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            P/G Name
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            {this.props.father_name}
                                        </Typography>
                                    </td>
                                </tr>



                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            Phone
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            {this.props.phone}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ verticalAlign: 'top', paddingTop: 0 }}>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            Email
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.nameFont}>
                                            {this.props.email}
                                        </Typography>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </Grid>

                </Grid>

                <table width="100%">

                    <tbody>
                        <tr style={{ verticalAlign: 'bottom' }}>
                            <td width="40%">
                                <Typography
                                    className={classes.downFont}
                                    style={{paddingLeft: '5px'}}
                                >
                                    
                                    Date of Issue<br />
                                    <b>1st August, {new Date().getFullYear()}</b>
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    className={classes.downFont}
                                    style={{ paddingLeft: '5px' }}
                                >
                                    Valid till<br />
                                    <b>
                                        {`July, ${new Date().getFullYear() + 1}`}
                                    </b>
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography

                                    align="center"
                                    className={classes.downFont}>
                                    <img
                                        src={require('./../../../assets/86s.jpg')}
                                        style={{ width: '40%' }}
                                    /><br />
                                    Principal
                                    
                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div

            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : '...'}
            </div>
        )
    }
}

