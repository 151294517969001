import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button, Typography } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CashBookTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onButtonClick = (id) => {
        this.props.onButtonClick(id);
    }


    getStatus(status) {
        if (status === 1) {
            return 'Yes';
        } else {
            return 'No';
        }
    }


    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Entry Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "VCR NO",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Narration",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "CR",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "DR",
                options: {
                    filter: true,
                    sort: true,
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll'
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index + 1, el.entry_date, el.vcr_no, el.narration, el.dr, el.cr]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={
                        <div>
                        <Typography variant="subtitle1">
                            {this.props.title}
                        </Typography>
                            <Typography variant="caption">
                                {this.props.subheader}
                            </Typography>
                        </div>
                    }
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
