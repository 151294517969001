import React, { Component } from 'react'
import { withStyles, Modal, Typography, IconButton, Grid, Button, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import Print from '@material-ui/icons/Print'
import Call from '@material-ui/icons/Call'
import Email from '@material-ui/icons/Email'
import ReactToPrint from "react-to-print";
import Cloud from '@material-ui/icons/Cloud';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import {GetData, PostData} from './../../api/service';
import { isMobile } from "react-device-detect";

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        minHeight: '100vh',
        

    },
    pullRight: {
        float: 'right'
    },
    image: {
        width: '100%'
    },
    modalClass: {
        overflowY: 'scroll'
    }
});


class LeaveModal extends Component {

    
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    className={classes.modalClass}
                >
                    <div className={classes.paper}>
                        <div className={classes.pullRight}>
                            <ReactToPrint
                                trigger={() => <IconButton>
                                    <Print />
                                </IconButton>}
                                content={() => this.componentRef}
                            />


                            <IconButton onClick={this.props.handleClose}>
                                <Close />
                            </IconButton>
                        </div>

                        <Details {...this.props} {...this.props.view_data} ref={el => (this.componentRef = el)} />

                    </div>
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(LeaveModal);


class Details extends Component {

    state = {
        blobUrl: '',
        blobLoad: false
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile(blob) {
        if (!isMobile) {
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:" + blob.mime + ";base64, " + encodeURI(blob.data) + "'></iframe>")
        } else {
            var blob1 = this.b64toBlob(blob.data, blob.mime);
            var blobUrl = URL.createObjectURL(blob1);

            if (blob.mime === 'application/pdf') {
                var link = document.createElement('a');
                link.href = blobUrl;
                link.download = `application.pdf`;
                link.click();
            } else {
                this.setState({
                    blobUrl,
                    blobLoad: true
                })
            }
        }
    }

    componentDidMount() {
        //seen status
        GetData(`/${this.props.p_apikey}/${this.props.id}/changetoseen`)
                .then((resp) => {
                    
                })
        

    }

    getFileData = (el, type) => {
        PostData(`/${this.props.p_apikey}/getfiledata`, el)
        .then((resp) => {
            this.showFile(resp)
        })
    }
  


    goodDate(date) {
        let d = new Date(date);
        let month = parseFloat(d.getMonth() + 1);
        let dateFormat = d.getDate() + '-' + month + '-' + d.getFullYear();
        return dateFormat;
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return <span style={{ color: 'black', display: 'flex', alignItems: 'center' }}><Cloud style={{ fontSize: 20 }} />&nbsp; Pending</span>

            case 1:
                return <span style={{ color: 'blue', display: 'flex', alignItems: 'center' }}><Cloud style={{ fontSize: 20 }} />&nbsp; Received</span>

            case 2:
                return <span style={{ color: 'green', display: 'flex', alignItems: 'center' }}><CheckCircle style={{ fontSize: 20 }} />&nbsp; Granted</span>

            case 3:
                return <span style={{ color: 'red', display: 'flex', alignItems: 'center' }}><ErrorOutline style={{ fontSize: 20 }} />&nbsp; Rejected</span>

            default:
                break;
        }
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let j = [];
        let d = this.props.application_file;
        if(Array.isArray(d) && d.length > 0){
           
            d.map((el,index) => {
                if(el.type === 'FILE'){
                    i.push(<Button key={index} variant="outlined" size="small" color="primary" onClick={this.getFileData.bind(this,el, 'FILE')}><CloudDownload /> &nbsp;Download</Button>)
                } else if(el.type === 'RETURNFILE'){
                    j.push(<Button key={index} variant="outlined" size="small" color="primary" onClick={this.getFileData.bind(this,el, 'RETURNFILE')}><CloudDownload /> &nbsp;Download</Button>)
                }
            })
        }

        if (Array.isArray(d) && d.length > 0) {
           
            d.map((el, index) => {
                if (el.type === 'FILE') {
                    i.push(<Button key={index} variant="outlined" size="small" color="primary" onClick={this.getFileData.bind(this, el, 'FILE')}><CloudDownload /> &nbsp;Download</Button>)
                } else if (el.type === 'RETURNFILE') {
                    j.push(<Button key={index} variant="outlined" size="small" color="primary" onClick={this.getFileData.bind(this, el, 'RETURNFILE')}><CloudDownload /> &nbsp;Download</Button>)
                }
            })
        }
        
        return (
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={12} lg={12}>
                        <Typography variant="h6" id="modal-title">
                            {this.props.leave_type}
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description">
                                By {this.props.email.toLowerCase()}
                        </Typography>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Start Date
                            </TableCell>
                                    <TableCell>
                                        {this.props.start_date}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        End Date
                                </TableCell>
                                    <TableCell>
                                        {this.props.end_date}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        No of Days
                                </TableCell>
                                    <TableCell>
                                        {this.props.no_of_days}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Application Status
                                </TableCell>
                                    <TableCell>
                                        {this.getStatus(this.props.action)}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        Message
                                </TableCell>
                                    <TableCell>
                                        {this.props.message}
                                    </TableCell>
                                </TableRow>


                                <TableRow>
                                    <TableCell>
                                        Application Files
                                </TableCell>
                                    <TableCell>
                                        {i}
                                    </TableCell>
                                </TableRow>



                            </TableBody>
                        </Table>


                    
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        {this.state.blobLoad ?
                            <img alt="" src={this.state.blobUrl} style={{ width: '100%' }} />
                            : null}
                    </Grid>

                    <Grid item xs={12} lg={12}>

                        <Typography
                            variant="body2"
                        >
                            Reply Comment
                        </Typography>

                        {this.props.return_comment}

                    </Grid>

                    <Grid item xs={12} lg={12}>

                        <Typography
                            variant="body2"
                        >
                            Reply Files
                        </Typography>


                        {j}
                        

                    </Grid>
                </Grid>

            </div>
        )
    }

}