import React, { Component } from 'react'
import { Card, withStyles, Typography, CardContent, Divider, CardHeader, Grid, FormControl, InputLabel, Select, MenuItem, TextField, FormLabel, RadioGroup, Radio, FormControlLabel, Button, LinearProgress, OutlinedInput } from '@material-ui/core';
import FileUpload from './../FileUpload/FileUpload'
import { GetData, PostData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';


const styles = theme => ({
    formControl: {
        width: '100%',
        marginTop: '18px'
    },
    textStyle: {
        fontWeight: 400
    },
    container: {
        padding: '5px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px'
        }
    },
    messageField: {
        width: '100%'
    },
    dateInput: {
        width: '100%',
        padding: theme.spacing.unit * 2,
        border: 'solid thin lightgrey',
        borderRadius: '3px',
        fontSize: 15

    }
})

class LeaveEdit extends Component {
    constructor(props) {
        super(props);

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = yyyy + '-' + mm + '-' + dd;

        this.state = {
            leave_id: '',
            start_date: today,
            end_date: today,
            is_approved_by_HOD: 0,
            message: '',
            application_file: [],
            apikey: '',
            no_of_days: 1,
            errors: {},
            leaves: [],
            isLoading: true,
            email: this.props.username,
            open: false,
            message_snack: "",
            error_msg: '',
            id: '',
            isLoadingData: false
        }

        this.onChange = this.onChange.bind(this);
        this.handlesDateChange = this.handlesDateChange.bind(this);
        this.handleeDateChange = this.handleeDateChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    __getInitialState() {

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = yyyy + '-' + mm + '-' + dd;

        this.setState({
            leave_id: '',
            start_date: today,
            end_date: today,
            is_approved_by_HOD: 0,
            message: '',
            application_file: [],
            apikey: '',
            no_of_days: 1,
            errors: {},
            email: this.props.username,
            error_msg: ''
        })
    }

    __initialFetch() {

        GetData(`/${this.props.username}/getleavebytype`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    leaves: resp,
                    isLoading: false
                })
                return resp;
            }).then((resp) => {
                GetData(`/${this.props.username}/getemployeeleavebalance`)
                    .then((resp) => {
                        //console.log(resp)
                        if (Array.isArray(resp)) {
                            this.setState({
                                balance: resp,
                                isBalLoaded: true
                            })
                        } else {
                            this.setState({
                                error_msg: "(No session activated. Please contact your administrator)"
                            })
                        }

                    })
            })


    }

    goodDate(value){
        let d = value;
        let d1 = d.split('-');
        return d1[2]+'-'+d1[1]+'-'+parseFloat(d1[0]);
    }

    componentDidMount() {
        this.__initialFetch();

        let edit_data = this.props.edit_data;

        if (this.props.open) {
            //console.log(this.props.edit_data.start_date)
            this.setState({
                ...this.props.edit_data,
                message: this.props.edit_data.message,
                application_file: this.props.edit_data.application_file,
                is_approved_by_HOD: this.props.edit_data.is_approved_by_HOD,
                isLoadingData: true,
                start_date: this.goodDate(this.props.edit_data.start_date),
                end_date: this.goodDate(this.props.edit_data.end_date),
            })
        }

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'leave_id') {
            let leave = this.state.leaves;
            let result = leave.filter(el => el.id === parseFloat(e.target.value));
            if (result.length > 0) {
                let leave_type = result[0].leave_type;
                this.setState({
                    leave_type
                })
            }
        }

        if (e.target.name === 'start_date') {
            let no_of_days = this.dateDiff(e.target.value, this.state.end_date)
            this.setState({
                no_of_days
            })
        }

        if (e.target.name === 'end_date') {
            let no_of_days = this.dateDiff(this.state.start_date, e.target.value)
            this.setState({
                no_of_days
            })
        }
    }

    handlesDateChange(date) {
        this.setState({
            start_date: date,
            no_of_days: this.dateDiff(date, this.state.end_date)
        })


    }

    handleeDateChange(date) {
        this.setState({
            end_date: date,
            no_of_days: this.dateDiff(this.state.start_date, date)
        })
    }

    onSetFile(e) {
        this.setState({
            application_file: e
        })
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            let { leave_id, start_date, end_date, no_of_days, is_approved_by_HOD, message, application_file, email } = this.state;
            const data = {
                leave_id, start_date, end_date, no_of_days, is_approved_by_HOD, message, application_file, email,
                session_id: 1,
                action: 0
            }

            PutData(`/${this.props.apikey}/${this.state.id}/updateemployeeleave`, data)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        open: true,
                        message_snack: resp
                    })
                    this.__getInitialState();
                    return resp;
                }).then((resp) => {
                    this.props.reloadList();
                })
        }
    }

    handleValidation() {
        let { leave_id, no_of_days, message, application_file } = this.state;
        let errors = this.state.errors;
        let isValid = true;

        if (leave_id !== "") {
            errors['leave_id'] = "";
        } else {
            errors['leave_id'] = "Required field";
            isValid = false;
        }


        if (no_of_days > 0) {
            errors['no_of_days'] = "";

        } else {
            errors['no_of_days'] = "Date input is invalid";
            isValid = false;
        }



        if (message !== "") {
            errors['message'] = "";

        } else {
            errors['message'] = "Required field"
            isValid = false;
        }

        if (application_file !== "") {
            errors['application_file'] = "";

        } else {
            errors['application_file'] = "Required field"
            isValid = false;
        }

        //check for validation for CL

        if (leave_id === 1 || leave_id === 2) {
            if (no_of_days > 3) {
                isValid = false;
                errors['no_of_days'] = "Cannot take CL for more than 3 days";
            } else {
                errors['no_of_days'] = "";
            }
        }

        if (!this.leaveCheck(leave_id, no_of_days)) {
            isValid = false;
            errors['no_of_days'] = "You have no sufficiant leave";
        } else {
            errors['no_of_days'] = "";
        }

        this.setState({
            errors
        })

        return isValid;
    }

    leaveCheck(leave_id, no_of_days) {
        //master leave should not be tempered with
        if (this.state.isBalLoaded) {
            let balance = this.state.balance;
            let result = balance.filter(el => el.leave_id === parseFloat(leave_id));
            if (result.length > 0) {
                if (result[0].leave_id < 7) {
                    if (no_of_days <= result[0].balance) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

            } else {
                return false;
            }

        } else {
            return false;
        }
    }

    dateDiff(start_date, end_date) {
        let ed = new Date(end_date);
        let sd = new Date(start_date);
        if (start_date <= end_date) {
            let timediff = Math.abs(ed.getTime() - sd.getTime());
            let diffdays = Math.ceil(timediff / (1000 * 3600 * 24)) + 1;
            return diffdays;
        } else {
            return 0;
        }

    }

    approve(value){
        if(value === '1'){
            return true;
        }else{
            return false;
        }
    }

    handleDelete(id){
        this.props.handleDelete(id)   
    }

    render() {
        let { classes } = this.props;
        let l = [];
        if (!this.state.isLoading) {
            let i = this.state.leaves;
            if (Array.isArray(i) && i.length > 0) {
                l = i.map((el, index) =>
                    <MenuItem key={index} value={el.id}>{el.leave_type}</MenuItem>
                )
            }
        }
        return (
            <div className={classes.container}>
                {this.state.isLoading ? <LinearProgress /> : null}

                {this.state.open ? <Snack open={this.state.open} message={this.state.message_snack} /> : null}
                <div >
                    <form onSubmit={this.onSubmit}>
                        <Card>
                            <CardHeader
                                title={`Edit leave ${this.state.error_msg}`}
                            >

                            </CardHeader>
                            <CardContent>

                                <Grid container spacing={24}>
                                    <Grid item xs={12} lg={4}>
                                        <FormControl variant="outlined" className={classes.formControl}>

                                            <InputLabel

                                            >
                                                Leave type
                                        </InputLabel>
                                            <Select

                                                value={this.state.leave_id}
                                                onChange={this.onChange}
                                                name="leave_id"
                                                inputProps={{
                                                    name: 'leave_id',
                                                    required: true,
                                                }}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={`200`}
                                                        name="leave_id"


                                                    />
                                                }

                                            >
                                                <MenuItem value="">
                                                    <em>Choose Leave Type</em>
                                                </MenuItem>
                                                {l}
                                            </Select>
                                            <div style={{ color: 'red' }}>{this.state.errors['leave_id']}</div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <InputLabel shrink className={classes.textStyle}>Start Date</InputLabel>
                                        <input
                                            type="date"
                                            name="start_date"
                                            value={this.state.start_date}
                                            onChange={this.onChange}
                                            className={classes.dateInput} 
                                            />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                            <InputLabel shrink className={classes.textStyle}>End Date</InputLabel>
                                            <input
                                                type="date"
                                                name="end_date"
                                                value={this.state.end_date}
                                                onChange={this.onChange}
                                                className={classes.dateInput}
                                            />
                                    </Grid>

                                    <Grid item xs={12} lg={4}>

                                        <TextField
                                            variant="outlined"
                                            label="No of Days"
                                            name="no_of_days"
                                            className={classes.textField}
                                            type="number"
                                            margin="normal"
                                            required
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.textStyle
                                                },
                                                required: true,

                                            }}
                                            value={this.state.no_of_days}
                                            onChange={this.onChange}
                                        />
                                        <div style={{ color: 'red' }}>{this.state.errors['no_of_days']}</div>
                                    </Grid>

                                    <Grid item xs={12} lg={4}>
                                        <InputLabel shrink className={classes.textStyle}>is approved by HOD ?</InputLabel>
                                        <Select
                                            value={this.state.is_approved_by_HOD}
                                            onChange={this.onChange}
                                            name="is_approved_by_HOD"
                                            inputProps={{
                                                name: 'is_approved_by_HOD',
                                                required: true
                                            }}

                                        >
                                            <MenuItem value="1">YES</MenuItem>
                                            <MenuItem value="0">NO</MenuItem>
                                    
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12} lg={12}>
                                        <Divider />
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            State your reason <span style={{ color: 'red' }}>{this.state.errors['message']}</span>
                                        </Typography>
                                        {this.state.isLoadingData ? <TextField
                                            variant="outlined"
                                            label="State your reason"
                                            name="message"
                                            className={classes.messageField}
                                            type="text"
                                            margin="normal"
                                            required
                                            multiline
                                            rows="6"
                                            InputProps={{
                                                required: true
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.textStyle
                                                },
                                                required: true,

                                            }}
                                            value={this.state.message}
                                            onChange={this.onChange}
                                        /> : null}

                                    </Grid>

                                    <Grid item xs={12} lg={12} style={{ backgroundColor: '#F2F2F2' }}>

                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            Upload Application File <span style={{ color: 'red' }}>{this.state.errors['application_file']}</span>
                                        </Typography>
                                        {this.state.isLoadingData ? <FileUpload
                                            dp={this.state.application_file}
                                            apikey={this.props.apikey}
                                            type="FILE"
                                            setDp={this.onSetFile.bind(this)}
                                        /> : null}
                                    </Grid>

                                    <Grid item xs={12} lg={12}>

                                        <div style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                type="button"
                                                onClick={this.props.handleClose}
                                            >
                                                Close
                                        </Button> &nbsp;&nbsp;
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                            >
                                                Update
                                        </Button> &nbsp;&nbsp;

                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                onClick={this.handleDelete.bind(this,this.state.id)}
                                            >
                                                Delete Leave
                                        </Button>
                                        </div>

                                    </Grid>
                                </Grid>

                            </CardContent>

                        </Card>
                    </form>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(LeaveEdit);
