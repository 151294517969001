import React, { Component } from 'react'
import { withStyles, Typography, Divider, Grid, Card, CardHeader, CardContent, Chip, IconButton, CardActions, Button, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload'
import Done from '@material-ui/icons/Done'
import DoneAll from '@material-ui/icons/DoneAll'
import Close from '@material-ui/icons/Close'
import { GetData, PostData } from '../../api/service';
import { isMobile } from "react-device-detect";
import { green, red } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const styles = theme => ({

})

class LeaveApprovalList extends Component {
    state = {
        isLoaded: false,
        leavesRequests: [],
        isAlreadyLoaded: false,
        alreadyLeavesRequests: []
    }

    __loadData = () => {
        GetData(`/getleavelistforhod`)
            .then((resp) => {
                console.log(resp)
                this.setState({
                    leavesRequests: resp,
                    isLoaded: true
                })
            }).then(() => {
                GetData(`/getalreadyapprovedlistforhod`)
                .then((res) => {
                    this.setState({
                        alreadyLeavesRequests: res,
                        isAlreadyLoaded: true
                    })
                })
            })
    }

    componentDidMount() {
        // get the list for approval

        this.__loadData();
    }

    refreshThePageData = () => {
        this.__loadData();
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <div>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Pending Leave Requests
                    </Typography>

                    <Divider />

                    <br />

                    {this.state.isLoaded ? (
                        <div>
                            {Array.isArray(this.state.leavesRequests) && this.state.leavesRequests.length > 0 ? (<Grid container spacing={24}>

                                {this.state.leavesRequests.map((el, index) =>
                                    <Grid key={index} item xs={12} sm={12} lg={3} md={3}>
                                        <LeaveApproveCardSmall
                                            {...el}
                                            refreshThePageData={this.refreshThePageData}
                                        />
                                    </Grid>
                                )}

                            </Grid>) : 'No Requests found'}
                        </div>
                    ) : 'Loading . . . '}
                </div>

                <br />

                <div>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Approved Leave Requests
                    </Typography>

                    <Divider />

                    <br />

                    {this.state.isAlreadyLoaded ? (
                        <div>
                            {Array.isArray(this.state.alreadyLeavesRequests) && this.state.alreadyLeavesRequests.length > 0 ? (<Grid container spacing={24}>

                                {this.state.alreadyLeavesRequests.map((el, index) =>
                                    <Grid key={index} item xs={12} sm={12} lg={3} md={3}>
                                        <LeaveApproveCardSmall
                                            {...el}
                                            refreshThePageData={this.refreshThePageData}
                                            apikey={this.props.apikey}
                                        />
                                    </Grid>
                                )}

                            </Grid>) : 'No Requests found'}
                        </div>
                    ) : 'Loading . . . '}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(LeaveApprovalList);

class LeaveApproveCardSmall extends Component {
    state = {
        blobUrl: '',
        blobLoad: false,
        dialog: false,
        id: ''
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile(blob) {
        if (!isMobile) {
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:" + blob.mime + ";base64, " + encodeURI(blob.data) + "'></iframe>")
        } else {
            var blob1 = this.b64toBlob(blob.data, blob.mime);
            var blobUrl = URL.createObjectURL(blob1);

            if (blob.mime === 'application/pdf') {
                var link = document.createElement('a');
                link.href = blobUrl;
                link.download = `application.pdf`;
                link.click();
            } else {
                this.setState({
                    blobUrl,
                    blobLoad: true
                })
            }
        }
    }

    getFileData = (el) => {
        PostData(`/getfiledata`, el)
            .then((resp) => {
                this.showFile(resp)
            })
    }

    changeStatus = (id) => {
        this.setState({
            dialog: true,
            id: id
        })
    }

    handleClose = () => {
        this.setState({
            dialog: false,
            id: ''
        })
    }

    handleListItemClick = (status) => {
        let d = {
            id: this.state.id,
            status: status
        }

        //send the data to the server

        PostData(`/changestatusbyhod`, d)
            .then((resp) => {
                this.setState({
                    dialog: false,
                    id: ''
                }, () => {
                    confirmAlert({
                        title: resp,
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.props.refreshThePageData()
                            }
                        ]
                    });
                })

            })
    }
    render() {
        let i = [];
        let j = [];

        if (Array.isArray(this.props.application_file) && this.props.application_file.length > 0) {
            i = this.props.application_file.map((el, index) =>
                <IconButton key={index} onClick={this.getFileData.bind(this, el)}>
                    <CloudDownload />
                </IconButton>
            )
        }

        if (Array.isArray(this.props.return_file) && this.props.return_file.length > 0) {
            j = this.props.return_file.map((el, index) =>
                <IconButton key={index} onClick={this.getFileData.bind(this, el)}>
                    <CloudDownload />
                </IconButton>
            )
        }
        return (
            <Card>

                <CardHeader
                    title={this.props.name}
                    subheader={
                        <div>
                            <Typography>
                                {this.props.leave_type}-{this.props.leave_desc}
                            </Typography>
                            {this.props.is_approved_by_HOD == '1' ? (
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: green[700],
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}

                                >
                                    <DoneAll style={{ width: '15px' }} /> &nbsp; Approved by HOD
                        </Typography>
                            ) : null}
                        </div>
                    }

                />
                <CardContent>
                    <Typography
                        variant="body2"
                    >
                        From: {this.props.start_date}
                    </Typography>
                    <Typography
                        variant="body2"
                    >
                        To: {this.props.end_date}
                    </Typography>
                    <Typography
                        variant="body2"
                    >
                        No of days: {this.props.no_of_days}
                    </Typography>

                    <Typography
                        variant="body2"
                    >
                        Reason:
                                    </Typography>
                    <Typography
                        variant="caption"
                        gutterBottom
                    >
                        {this.props.message}
                    </Typography>

                    
                    <Typography
                        variant="body2"
                    >
                        Uploaded Files:
                    </Typography>
                    {i}
                </CardContent>

                    {this.props.action != '0' ? <Divider /> : null}
                
                {this.props.action != '0' ? (<CardContent>

                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                    >

                        <Typography
                            variant="body2"
                        >
                            Application Status:
                    </Typography> &nbsp;&nbsp; <Chip
                            color="primary"
                            label={
                                <div>
                                    {this.props.action == '0' ? 'Pending' : null}
                                    {this.props.action == '1' ? 'Received' : null}
                                    {this.props.action == '2' ? 'Granted' : null}
                                    {this.props.action == '3' ? 'Rejected' : null}
                                </div>
                            }
                        />

                    </div>




                    {this.props.return_comment != '' ? (
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            <b>Comment</b>: {this.props.return_comment}
                        </Typography>
                    ) : null}

                    <Typography
                        variant="body2"
                    >
                        Return Files:
                    </Typography>
                    {j}

                </CardContent>) : null}

                <CardActions>
                    {this.props.action == '0' ? (<Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={this.changeStatus.bind(this, this.props.id)}
                    >
                        Change Status
                </Button>) : null}
                </CardActions>


                {this.state.dialog ? (<DialogStatus
                    handleClose={this.handleClose}
                    open={this.state.dialog}
                    apikey={this.props.apikey}
                    handleListItemClick={this.handleListItemClick}
                />) : null}
            </Card>
        )
    }
}

export const DialogStatus = props => {
    return (
        <div>
            <Dialog onClose={props.handleClose} open={props.open}>
                <DialogTitle>Change Status</DialogTitle>
                <List>

                    <ListItem button onClick={() => props.handleListItemClick('1')}>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: green[300] }}>
                                <Done />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Mark as Approved`} />
                    </ListItem>

                    <ListItem button onClick={() => props.handleListItemClick('0')}>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: red[300] }}>
                                <Close />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Mark as Rejected`} />
                    </ListItem>
                </List>
                <DialogActions>
                    <Button
                        onClick={props.handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}