import React, { Component } from 'react'
import { withStyles, Typography, Button } from '@material-ui/core';

const styles = theme => ({

})

class ViewTableModifiedPostAdmission extends Component {
    render() {
        return (
            <div>
                <TableData
                    data={this.props.data}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ViewTableModifiedPostAdmission);



class TableData extends Component {

    subjectsView = (subjects) => {
        let a = [];

        if (Array.isArray(subjects) && subjects.length > 0) {
            a = subjects.map((el, index) => {
                return (
                    <tr key={index}>
                        <td>
                            {el.sub_name} (<b>
                                {
                                    el.is_compulsory === '1' ? 'Compulsory' : null
                                }

                                {
                                    el.is_MIL === '1' ? 'MIL' : null
                                }
                                {
                                    el.is_major === '1' ? 'Major' : null
                                }
                                {
                                    el.is_generic === '1' ? 'Generic' : null
                                }
                            </b>)
                        </td>
                    </tr>
                )
            }

            )
        }

        return <table>
            <tbody>
                {a}
            </tbody>
        </table>;
    }

    bestSubjectView = (data) => {

        let a = [];



        if (Array.isArray(data) && data.length > 0) {
            data.map((el, index) => {
                if (el.is_major !== '1' || el.type != 'major') {
                    if (el.sub_name != undefined) {
                        a.push(el.sub_name)
                    } else {
                        a.push(el.label)
                    }
                }

            
            }

            )
        } else {
            data = JSON.parse(data);
            if (Array.isArray(data) && data.length > 0) {
                data.map((el, index) => {
                    if (el.is_major !== '1' || el.type != 'major') {
                        if (el.sub_name != undefined) {
                            a.push(el.sub_name)
                        } else {
                            a.push(el.label)
                        }
                    }
                }

                )
            }

        }



        return a.join(',');
    }

    bestSubjectView1 = (data) => {

        let a = [];




        if (Array.isArray(data) && data.length > 0) {
            data.map((el, index) => {
                if (el.is_major === '1' || el.type == 'major') {
                    if (el.sub_name != undefined) {
                        a.push(el.sub_name)
                    } else {
                        a.push(el.label)
                    }
                }
            }

            )
        } else {
            data = JSON.parse(data);
            if (Array.isArray(data) && data.length > 0) {
                data.map((el, index) => {
                    if (el.is_major === '1' || el.type == 'major') {
                        if (el.sub_name != undefined) {
                            a.push(el.sub_name)
                        } else {
                            a.push(el.label)
                        }
                    }
                }

                )
            }

        }



        return a.join(',');
    }


    render() {
        return (
            <div style={{ overflowX: 'scroll' }}>

                <table width="100%" >
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Admitted List, <b>2019-2020</b>
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Course: <b>{this.props.course}</b> &nbsp;&nbsp; Stream: <b>{this.props.stream}</b>
                                </Typography>

                                {this.props.course === 'TDC' && this.props.course_type !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Course Type: <b>{this.props.course_type}</b>
                                </Typography>) : null}



                                {this.props.sub_name !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Subject: <b>{this.props.sub_name}</b>
                                </Typography>) : null}
                            </td>

                            <td width="20%" align="center">

                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" className="table" id="table-to-xls">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Roll No</td>
                            <td>Name</td>
                            <td>Major (if any)</td>
                            <td>Subjects Taken</td>
                            <td>Course</td>
                            <td>Stream</td>
                            <td>Course Type</td>
                            <td>Semester</td>
                            <td>Email</td>
                            <td>Phone</td>
                            <td>Caste</td>
                            <td>Gender</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((el, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.roll_no}</td>
                                <td>{el.name}</td>
                                <td>{this.bestSubjectView1(el.subjects)}</td>
                                <td>{this.bestSubjectView(el.subjects)}</td>
                                <td>{el.course}</td>
                                <td>{el.stream}</td>
                                <td>{el.course_type}</td>
                                <td>{el.semester}</td>
                                <td>{el.email}</td>
                                <td>{el.phone}</td>
                                <td>{el.caste}</td>
                                <td>{el.gender}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}