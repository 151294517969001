import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const styles = theme => ({

})

class ViewTableNewAdmission extends Component {
    render() {
        return (
            <div>

                <div style={{ textAlign: 'center' }}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={`Admission_list_${this.props.course}_${this.props.stream}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                </div>


                <TableData
                    data={this.props.data}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ViewTableNewAdmission);



class TableData extends Component {

    subjectsView = (subjects) => {
        let a = [];

        if (Array.isArray(subjects) && subjects.length > 0) {
            a = subjects.map((el, index) => {
                return (
                    <tr key={index}>
                        <td>
                        {el.sub_name} <b>
                            {
                                el.is_compulsory === '1' ? '(Compulsory)' : null
                            }

                            {
                                el.is_MIL === '1' ? '(MIL)' : null
                            }
                            {
                                el.is_major === '1' ? '(HONOURS)' : null
                            }
                            {
                                el.is_generic === '1' ? '(Generic)' : null
                            }
                        </b>
                        </td>
                    </tr>
                )
            }

            )
        }

        return <table>
            <tbody>
                {a}
            </tbody>
        </table>;
    }

    bestSubjectView = (data) => {

        let a = [];

        if (Array.isArray(data) && data.length > 0) {
            a = data.map((el, index) => 
                <tr key={index}>
                    <td>{el.subject}</td>
                    <td>{el.marks_secured} / {el.total_marks}</td>
                </tr>
            )
        }

     

        return <table>
                <tbody>
                    {a}
                </tbody>
            </table>
    }
    render() {
        return (
            <div style={{overflowX: 'scroll'}}>

                

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Admission List List, <b>2019-20</b>
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Course: <b>{this.props.course}</b> &nbsp;&nbsp; Stream: <b>{this.props.stream}</b>
                                </Typography>

                                {this.props.course === 'TDC' && this.props.course_type !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Course Type: <b>{this.props.course_type}</b>
                                </Typography>) : null}

                                

                                {this.props.caste !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Semester: <b>{this.props.semester}</b>
                                </Typography>) : null}
                            </td>

                            <td width="20%" align="center">

                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <table width="100%" className="table" id="table-to-xls">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Form No</td>
                            <td>Course</td>
                            <td>Stream</td>
                            <td>Course Type</td>
                            <td>Semester</td>
                            <td>Name</td>
                            <td>Father's Name</td>
                            <td>Phone</td>
                            <td>Email</td>
                            <td>Marks</td>
                            <td>Percentage</td>
                            <td>Subjects Taken</td>
                            <td>Best of Three</td>
                            <td>Marksheet</td>
                            <td>Payment Status</td>
                            <td>Payment</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((el, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.form_id}</td>
                                <td>{el.course}</td>
                                <td>{el.stream}</td>
                                <td>{el.course_type}</td>
                                <td>{el.semester}</td>
                                <td>{el.name}</td>
                                <td>{el.father_name}</td>
                                <td>{el.phone}</td>
                                <td>{el.email}</td>
                                <td>{el.marks_obtained} {el.ranking_serial != null ? `Ranking Serial: ${el.ranking_serial}`: '' } </td>
                                <td>{el.percentage} {el.ranking_score}</td>
                                <td>{this.subjectsView(el.subjects)}</td>
                                <td>{this.bestSubjectView(el.best_subjects)}</td>
                                <td>{this.bestSubjectView(el.last_marks)}</td>
                                <td>{el.payment_status == '1' ? `DONE` : `NOTDONE`}</td>
                                <td>
                                    {el.payment_status == '1' ? (
                                        <div>
                                            Ref: {el.transaction['Ref_no']}<br />
                                            Amount: {el.transaction['amount']}<br />
                                        </div>
                                    ) : null}
                                </td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}