import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({

})

class ViewTable extends Component {
    render() {
        return (
            <div>
                <TableData
                    data={this.props.data}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ViewTable);



class TableData extends Component {

    subjectsView = (subjects) => {
        let a = [];

        if (Array.isArray(subjects) && subjects.length > 0) {
            a = subjects.map((el, index) => {
                return (
                    <tr key={index}>
                        <td>
                        {el.sub_name} (<b>
                            {
                                el.is_compulsory === '1' ? 'Compulsory' : null
                            }

                            {
                                el.is_MIL === '1' ? 'MIL' : null
                            }
                            {
                                el.is_major === '1' ? 'Major' : null
                            }
                            {
                                el.is_generic === '1' ? 'Generic' : null
                            }
                        </b>)
                        </td>
                    </tr>
                )
            }

            )
        }

        return <table>
            <tbody>
                {a}
            </tbody>
        </table>;
    }

    bestSubjectView = (data) => {

        let a = [];

        if (Array.isArray(data) && data.length > 0) {
            a = data.map((el, index) => 
                <tr key={index}>
                    <td>{el.subject}</td>
                    <td>{el.marks_secured} / {el.total_marks}</td>
                </tr>
            )
        }

     

        return <table>
                <tbody>
                    {a}
                </tbody>
            </table>
    }
    render() {
        return (
            <div>

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Merit List, <b>2019-2020</b>
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Course: <b>{this.props.course}</b> &nbsp;&nbsp; Stream: <b>{this.props.stream}</b>
                                </Typography>

                                {this.props.course === 'TDC' && this.props.course_type !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Course Type: <b>{this.props.course_type}</b>
                                </Typography>) : null}

                                {this.props.sub_name !== '' ? (<Typography
                                    variant="body1"
                                >
                                   Honours Preference: <b>{this.props.sub_name}</b>
                                </Typography>) : null}

                                {this.props.caste !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Caste: <b>{this.props.caste}</b>
                                </Typography>) : null}
                            </td>

                            <td width="20%" align="center">

                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <table width="100%" className="table" id="table-to-xls">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Form No</td>
                            <td>Name</td>
                            <td>Marks</td>
                            <td>Percentage / Ranking Score </td>
                            <td>Ranking Serial (for PG Only)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((el, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.form_id}</td>
                                <td>{el.name}</td>
                                <td>{el.marks_obtained}</td>
                                <td>{el.percentage}</td>
                                <td>{el.ranking_serial}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}