import React, { Component } from 'react'
import { GetData, PostData } from '../../api/service';
import LeaveTable from './LeaveTable';
import LeaveModal from './LeaveModal';
import { withStyles } from '@material-ui/core';
import ApproveFinal from './ApproveFinal';
import Snack from '../Snackbar/Snack';

const styles = theme => ({
    table: {
        display: 'block'
    }
})

class LeaveApprove extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leaves: [],
            isLoading: true,
            view_data: [],
            view: false,
            edit: false,
            edit_data: [],
            apikey: '',
            p_apikey: '',
            open: false,
            message_snack: ''
        }
    }

    __initialFetch() {
        //console.log(this.props.match.params.action)
        GetData(`/${this.props.match.params.action}/getemployeeleave`)
            .then((resp) => {
                console.log(resp);
                this.setState({
                    leaves: resp,
                    isLoading: false
                })
            })
    }

    _getApi(){
        GetData(`/${this.props.username}/getprincipalapi`)
            .then((resp) => {
                if (resp !== false) {
                    this.setState({
                        p_apikey: resp
                    })
                }else{
                    this._getApi();
                }
            })
    }

    componentDidMount() {
        this.__initialFetch();
        //get apikey by sending the email of my own
        this._getApi()

    }

    actionClick(el) {
            this.setState({
                edit_data: el,
                edit: true
            })

    }

    onViewClick(el) {
        this.setState({
            view: true,
            view_data: el
        })
    }

    reloadList() {
        this.__initialFetch();
        this.setState({
            edit_data: [],
            edit: false
        })
    }

    onSetData(data){
        PostData(`/${this.state.p_apikey}/${data.id}/changeleavestatus`, data)
            .then((resp) => {

                this.setState({
                    open: true,
                    message_snack: 'Successfully Updated'
                })
                this.reloadList()

            })
    }

    render() {
        let { classes } = this.props;
        return (
            <div>

                {this.state.open ? <Snack open={this.state.open} message={this.state.message_snack} /> : null}

                {this.state.isLoading ? null : <LeaveTable
                    table_data={this.state.leaves}
                    isLoading={this.state.isLoading}
                    actionClick={this.actionClick.bind(this)}
                    onViewClick={this.onViewClick.bind(this)}
                />}

                <LeaveModal
                    open={this.state.view}
                    handleClose={() => {
                        this.setState({
                            view: false,
                            view_data: []
                        })
                    }}
                    view_data={this.state.view_data}
                    p_apikey={this.state.p_apikey}
                />

                {this.state.edit ? <ApproveFinal
                    open={this.state.edit}
                    handleClose={() => {
                        this.setState({
                            edit: false,
                            edit_data: []
                        })
                    }}
                    edit_data = {this.state.edit_data}
                    apikey={this.props.apikey}
                    onSetData={this.onSetData.bind(this)}
                />: null}

            </div>
        )
    }
}

export default withStyles(styles)(LeaveApprove);