import React, { Component } from 'react'
import { withStyles, Card, CardContent, Typography, FormControl, InputLabel, OutlinedInput, MenuItem, Select, Grid, CardActions, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import Sel from 'react-select'
import { GetData, PostData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const styles = theme => ({

    cardCont: {
        textAlign: 'center'
    },
    formControl: {
        width: '40%'
    },
    box: {
        border: 'solid thin lightgrey',
        padding: theme.spacing.unit * 2
    }
})


const PG_SUB = [
    {
        "id": "46",
        "sub_code": "MASS(PG)",
        "value": "MASS(PG)",
        "dept_code": "ASM",
        "sub_name": "Assamese (PG)",
        "label": "Assamese (PG)",
        "course": "PG",
        "stream": "[{\"label\": \"ARTS\", \"value\": \"ARTS\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "47",
        "sub_code": "ZOO(PG)",
        "value": "ZOO(PG)",
        "dept_code": "ZOO",
        "sub_name": "Zoology (PG)",
        "label": "Zoology (PG)",
        "course": "PG",
        "stream": "[{\"label\": \"SCIENCE\", \"value\": \"SCIENCE\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "69",
        "sub_code": "BEN(PG)",
        "value": "BEN(PG)",
        "dept_code": "BEN",
        "sub_name": "Bengali(PG)",
        "label": "Bengali(PG)",
        "course": "PG",
        "stream": "[{\"label\": \"ARTS\", \"value\": \"ARTS\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "70",
        "sub_code": "EDU(PG)",
        "value": "EDU(PG)",
        "dept_code": "EDU",
        "sub_name": "Education(PG)",
        "label": "Education(PG)",
        "course": "PG",
        "stream": "[{\"label\": \"ARTS\", \"value\": \"ARTS\"}]",
        "total_marks": "100",
        "pass_marks": "23",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "71",
        "sub_code": "GEO(PG)",
        "value": "GEO(PG)",
        "dept_code": "GEO",
        "sub_name": "Geography(PG)",
        "label": "Geography(PG)",
        "course": "PG",
        "stream": "[{\"label\": \"ARTS\", \"value\": \"ARTS\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "72",
        "sub_code": "BIOTECH(PG)",
        "value": "BIOTECH(PG)",
        "dept_code": "BIOT",
        "sub_name": "Biotechnology(PG)",
        "label": "Biotechnology(PG)",
        "course": "PG",
        "stream": "[{\"label\": \"SCIENCE\", \"value\": \"SCIENCE\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "0",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    },
    {
        "id": "73",
        "sub_code": "ECO",
        "value": "ECO",
        "dept_code": "ECO",
        "sub_name": "ECONOMICS(PG)",
        "label": "ECONOMICS(PG)",
        "course": "PG",
        "stream": "[{\"label\": \"ARTS\", \"value\": \"ARTS\"}]",
        "total_marks": "100",
        "pass_marks": "30",
        "is_MIL": "0",
        "is_admission": "1",
        "is_major": "0",
        "is_compulsory": "0",
        "is_generic": "0"
    }
]

class ChoiceFillUp extends Component {
    state = {
        application_status: 1,
        selected: [],
        major_list: [],
        generic_list: [],
        compulsory_list: [],
        mil_list: [],
        subject_list: [],
        f_subject_list: [],
        isLoaded: false,
        isSubLoaded: false,
        errors: [],
        isSubmitted: false
    }

    __getSubjects = () => {
        GetData(`/${this.state.course}/${this.state.stream}/getsubjectcombinationbycourse`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    subject_list: resp
                })
            })
    }

    __getSubjectList = () => {



        let course = this.state.course;
        let stream = this.state.stream;
        let semester = this.state.semester;
        if (stream === '') {
            stream = 'none';
        }

        GetData(`/${course}/${stream}/${semester}/getadmissionsubjectbysemester`)
            .then((resp) => {

                //console.log(resp)
                // filter major subjects

                if (this.state.course === 'TDC') {
                    let subjects = resp; // all subjects

                    // 1 - Compulsory Subjects - 1 or 2
                    // 2 - MIL Subjets 
                    // 3 - Major Preference Subject
                    // 4 - Generic Preference Subject

                    //console.log(resp)



                    let comp = subjects.filter(el => el.is_compulsory == '1'); // Compulsory list
                    let maj = subjects.filter(el => el.is_major == '1'); // major list
                    let mil = subjects.filter(el => el.is_MIL == '1'); // mil list
                    let gen = subjects.filter(el => el.is_generic == '1'); // generic list



                    // fetch the compulsory subjects

                    let comp_1 = {};
                    let comp_2 = {};
                    let comp_3 = {};
                    let comp_4 = {};

                    if (Array.isArray(comp) && comp.length > 0) {
                        console.log(comp)
                        if (comp.length === 1) {
                            comp_1 = comp[0];
                        }

                        if (comp.length === 2) {
                            comp_1 = comp[1];
                            comp_2 = comp[0];
                        }

                        if (comp.length === 3) {
                            comp_1 = comp[2];
                            comp_2 = comp[0];
                            comp_3 = comp[1];
                        }

                        //commerce
                        if (comp.length === 4) {
                            comp_1 = comp[0];
                            comp_2 = comp[1];
                            if (this.state.course_type === 'REGULAR') {
                                comp_3 = comp[3];
                            } else if (this.state.course_type === 'HONOURS') {
                                comp_3 = comp[2];
                            }


                        }

                        if (comp.length > 3) {
                            comp_1 = comp[0];
                            comp_2 = comp[4];
                            comp_3 = comp[5];
                            comp_4 = comp[6];
                        }

                        if (comp.length > 3 && this.state.semester == null) {
                            comp_1 = comp[0];
                            comp_2 = comp[1];
                            comp_3 = comp[2];
                            comp_4 = comp[3];

                        }

                        if (this.state.course == 'TDC' && this.state.stream == 'COMMERCE' && (this.state.semester == null || this.state.semester == '1')) {
                            maj = gen
                        }

                        if (course == 'TDC' && stream === 'COMMERCE' && semester == '5') {
                            if (comp.length > 3) {
                                comp_1 = comp[0];
                                comp_2 = comp[1];
                                comp_3 = comp[2];
                                comp_4 = comp[3];
                            }
                        }
                    }




                    this.setState({
                        major_list: maj,
                        generic_list: gen,
                        compulsory_list: comp,
                        mil_list: mil,
                        compulsory_1: comp_1,
                        compulsory_2: comp_2,
                        compulsory_3: comp_3,
                        generic_1: comp_4,
                        f_major_list: maj,
                        f_generic_list: gen,
                        isLoaded: true,
                        isSubLoaded: true
                    }, () => {

                        //console.log(this.state)
                        let data = this.props.data;

                        let generic_1 = data.generic_1;

                        //for commerce students only
                        /* if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'REGULAR') {
                            generic_1 = {
                                label: 'English Language',
                                value: 'CFE',
                                sub_code: 'CFE',
                                sub_name: 'English Language',
                                dept_code: 'ENG'
                            }
                        }else{
                            generic_1 = this.state.generic_1
                        } */



                        this.setState({
                            major_1: data.major_1,
                            major_2: data.major_2,

                            generic_2: data.generic_2,
                            generic_3: data.generic_3,
                            mil: data.mil,
                            optional_subjects: data.optional_subjects,
                            subject_comb: data.subject_comb,
                        })


                    })
                } else if (this.state.course === 'HS') {
                    let subjects = resp; // all subjects of course + stream

                    // 1 - Compulsory Subject - compulsory_1
                    // 2 - MIL Subject - mil
                    // 3 - Subject Combinations - subject_comb + optional_subjects 


                    let comp = subjects.filter(el => el.is_compulsory === '1'); // compulsory
                    let mil = subjects.filter(el => el.is_MIL === '1'); // mil

                    //select one comp_sub
                    let com_sub = {};


                    if (Array.isArray(comp) && comp.length > 0) {
                        com_sub = comp[0]
                    }


                    this.setState({
                        mil_list: mil,
                        compulsory_1: com_sub,
                        compulsory_list: comp,
                        isLoaded: true,
                        isSubLoaded: true
                    }, () => {
                        this.__getSubjects();
                        let data = this.props.data;
                        this.setState({
                            compulsory_1: data.compulsory_1,
                            mil: data.mil,
                            optional_subjects: data.optional_subjects,
                            subject_comb: data.subject_comb
                        })
                    })
                } else if (this.state.course === 'BSC-BIOTECHNOLOGY') {
                    let subjects = resp;
                    if (Array.isArray(resp) && resp.length > 0) {
                        this.setState({
                            compulsory_1: resp[0],
                            isLoaded: true,
                            isSubLoaded: true
                        })

                    }
                } else if (this.state.course === 'BCA') {
                    let subjects = resp;
                    if (Array.isArray(resp) && resp.length > 0) {
                        this.setState({
                            compulsory_1: resp[0],
                            isLoaded: true,
                            isSubLoaded: true
                        })

                    }
                } else if (this.state.course === 'PG') {
                    let subjects = resp;
                    //console.log(resp)
                    if (Array.isArray(resp) && resp.length > 0) {
                        this.setState({
                            
                            compulsory_list: PG_SUB,
                            isLoaded: true,
                            isSubLoaded: true
                        })

                    }
                }
            })
    }

    componentDidMount() {

        this.setState({
            ...this.props.data
        }, () => this.__getSubjectList())
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course_type') {
            this.__getSubjectList()
        }
        if (e.target.name === 'subject_comb') {
            let subject_list = this.state.subject_list;
            let r = subject_list.filter(el => el.id === parseFloat(e.target.value));
            if (Array.isArray(r) && r.length > 0) {
                this.setState({
                    optional_subjects: r[0]['subjects']
                })
            }
        }
    }

    onSubChange = (name, e) => {


        if (e !== null) {
            this.setState({
                [name]: e
            })
        } else {
            this.setState({
                [name]: {}
            })
        }
    }

    handleChange = (panel) => {

        this.setState({
            isExpanded: !this.state.isExpanded
        })

    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isSubmitted: true
        })

        let subjects = [];
        let a = [];

        // arrange all the subjects 

        // HS 

        // Arts - compulsory_1 + mil + optional_subjects

        // Science - compulsory_1 + mil + optional_subjects

        // Commerce - compulsory_1 + mil + optional_subjects

        if (this.state.course === 'HS') {
            a.push(this.state.compulsory_1);
            a.push(this.state.mil);
            subjects = a.concat(this.state.optional_subjects)
        }

        // TDC

        // Arts - HONOURS - compulsory_2 + mil + major_1 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'ARTS' && this.state.course_type === 'HONOURS') {

            //subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.mil);
            subjects.push(this.state.major_1);
            subjects.push(this.state.generic_1);

        }

        // Arts - REGULAR - compulsory_1 + compulsory_2 + mil + generic_1 + generic_2 

        if (this.state.course === 'TDC' && this.state.stream === 'ARTS' && this.state.course_type === 'REGULAR') {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.mil);
            subjects.push(this.state.generic_1);
            subjects.push(this.state.generic_2);
            //subjects.push(this.state.generic_3);
        }

        // Science - HONOURS - compulsory_1 + compulaory_2 + major_1 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'SCIENCE' && this.state.course_type === 'HONOURS') {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.major_1);
            subjects.push(this.state.generic_1);
        }

        // Science - REGULAR - compulsory_1 + compulaory_2 + compulsory_3 + generic_1 + generic_2

        if (this.state.course === 'TDC' && this.state.stream === 'SCIENCE' && this.state.course_type === 'REGULAR') {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.generic_1);
            subjects.push(this.state.generic_2);
            //subjects.push(this.state.generic_3);
        }

        // Commerce - HONOURS - compulsory_1 + compulaory_2 + compulsory_3 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'HONOURS' && (this.state.semester == 1 || this.state.semester == null)) {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.generic_1);
            subjects.push(this.state.major_1);


        }

        // Commerce - REGULAR - compulsory_1 + compulaory_2 + compulsory_3 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'REGULAR' && (this.state.semester == 1 || this.state.semester == null)) {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);

            subjects.push(this.state.generic_1);

        }


        // Commerce - HONOURS - compulsory_1 + compulaory_2 + compulsory_3 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'HONOURS' && this.state.semester == 3) {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.mil);
            subjects.push(this.state.major_1);
            subjects.push(this.state.generic_1);
        }

        // Commerce Honours - compuslory_1 + compulsory_2 + compulsory_3 + major_1 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'HONOURS' && this.state.semester == 5) {

            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.major_1);
            subjects.push(this.state.generic_1);
        }

        // Commerce - REGULAR - compulsory_1 + compulaory_2 + compulsory_3 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'REGULAR' && this.state.semester == 3) {
            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.mil);
            subjects.push(this.state.generic_1);
            //subjects.push(this.state.generic_2);
        }

        // Commerce Honours - compuslory_1 + compulsory_2 + compulsory_3 + generic_1

        if (this.state.course === 'TDC' && this.state.stream === 'COMMERCE' && this.state.course_type === 'REGULAR' && this.state.semester == 5) {

            subjects.push(this.state.compulsory_1);
            subjects.push(this.state.compulsory_2);
            subjects.push(this.state.compulsory_3);
            subjects.push(this.state.generic_1);
        }


        // Bio-technology - BIOTECH(M)

        if (this.state.course === 'BSC-BIOTECHNOLOGY') {
            a.push(this.state.compulsory_1);
            subjects = a.concat(this.state.optional_subjects)
        }

        // BCA - BCA

        if (this.state.course === 'BCA') {
            a.push(this.state.compulsory_1);
            subjects = a.concat(this.state.optional_subjects)
        }


        if (this.state.course === 'PG') {
            a.push(this.state.compulsory_1);
            subjects = a.concat(this.state.optional_subjects)
        }


        //check the subjects array for empty value


        let s = subjects.filter(el => Object.keys(el).length !== 0 && el !== null);




        let d = {
            ...this.state,
            subjects: s
        }




        PostData(`/${this.props.apikey}/addstudentfromadmission`, d)
            .then((resp) => {
                //console.log(resp);
                confirmAlert({
                    title: 'Successfully Submitted',
                    message: resp,
                    buttons: [
                        {
                            label: 'Okay',
                            onClick: () => window.location.reload()
                        }
                    ]
                });
            })



    }

    showData(value) {
        if (Array.isArray(value) && value.length > 0) {
            let new_arr = value.map((el, index) => el.label);

            return new_arr.join(",");
        } else {
            return '';
        }
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let subject_list = this.state.subject_list;
        if (Array.isArray(subject_list)) {
            i = subject_list.map((el, index) => {
                return (
                    <MenuItem value={el.id} key={index}>{this.showData(el.subjects)}</MenuItem>
                )
            })
        }
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardContent className={classes.cardCont}>



                            <br />
                            <br />

                            <table width="100%" className="table">

                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography>
                                                Name: {this.state.name}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography>
                                                Form ID: {this.state.form_id}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography>
                                                Course: {this.state.course} {this.state.course === 'TDC' ? this.state.course_type : null}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography>
                                                Stream: {this.state.stream}
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                            {this.state.course === 'TDC' ? (<table width="100%" className="table">

                                <tbody>
                                    <tr>
                                        <td colSpan="4">
                                            <Typography>
                                                Combination of Subject sougth for TDC
                                </Typography>
                                        </td>
                                    </tr>

                                    <tr>

                                        <td >
                                            <Typography>
                                                Compulsory: {this.state.compulsory_1.label}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography>
                                                {this.state.compulsory_2.label}
                                            </Typography>
                                        </td>
                                        <td>

                                            {this.state.stream === 'ARTS' ? (
                                                <Typography>
                                                    MIL: {this.state.mil.label}
                                                </Typography>
                                            ) : null}

                                            {this.state.stream === 'COMMERCE' ? (
                                                <Typography>
                                                    {this.state.compulsory_3.label}
                                                </Typography>
                                            ) : null}
                                        </td>

                                    </tr>

                                </tbody>


                                {this.state.course_type === 'HONOURS' ? (

                                    <tbody>
                                        {this.state.stream !== 'COMMERCE' ? (
                                            <tr>
                                                <td>
                                                    <Typography>
                                                        Major
                                        </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        Preference 1: {this.state.major_1.label}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        Preference 2: {this.state.major_2.label}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        ) : (
                                                <tr>
                                                    <td>
                                                        <Typography>
                                                            Generic Subject :  {this.state.generic_1.label}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        &nbsp;
                                        </td>
                                                    <td>
                                                        &nbsp;
                                        </td>
                                                </tr>
                                            )}
                                    </tbody>

                                ) : null}

                                {this.state.course_type === 'HONOURS' ? (

                                    <tbody>
                                        {this.state.stream !== 'COMMERCE' ? (
                                            <tr>
                                                <td>
                                                    <Typography>
                                                        Generic
                                        </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        Preference 1: {this.state.generic_1.label}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        Preference 2: {this.state.generic_2.label}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>

                                ) : null}

                                {this.state.course_type === 'REGULAR' ? (
                                    <tbody>
                                        {this.state.stream !== 'COMMERCE' ? (
                                            <tr>
                                                <td>
                                                    <Typography>
                                                        CC Subject:    {this.state.generic_1.label}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        {this.state.generic_2.label}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography>
                                                        {this.state.generic_3.label}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        ) : (
                                                <tr>
                                                    <td>
                                                        <Typography>
                                                            CC Subject:   {this.state.generic_1.label}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        &nbsp;
                                        </td>
                                                    <td>
                                                        &nbsp;
                                        </td>
                                                </tr>
                                            )}

                                    </tbody>
                                ) : null}


                            </table>) : null}





                            {this.state.course === 'HS' ? (

                                <table width="100%" className="table">

                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <Typography>
                                                    Combination of Subjects sougth for HS Course
                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Compulsory: {this.state.compulsory_1.label}
                                                </Typography>
                                            </td>

                                            <td colSpan="2">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    MIL: {this.state.mil.label}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4">
                                                <Grid container spacing={16}>
                                                    {this.state.optional_subjects.map((el, index) =>

                                                        <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                            <Typography variant="body2" >
                                                                {index + 1} : {el.label}
                                                            </Typography>
                                                        </Grid>

                                                    )}
                                                </Grid>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>) : null}


                            {this.state.course === 'PG' ? (

                                <table width="100%" className="table">

                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <Typography>
                                                    Subject taken
                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Compulsory: {this.state.compulsory_1.label}
                                                </Typography>
                                            </td>

                                            
                                        </tr>
                                        
                                    </tbody>

                                </table>) : null}


                            <br />

                            {this.state.course === 'TDC' ? (

                                <div style={{ textAlign: 'left' }}>
                                    <Typography
                                        variant="title"
                                        gutterBottom
                                    >Select Course Type</Typography>
                                    <select
                                        className="form-control"
                                        onChange={this.onChange}
                                        value={this.state.course_type}
                                        name="course_type"
                                    >
                                        <option value="">Choose Course Type</option>
                                        <option value="HONOURS">HONOURS</option>
                                        <option value="REGULAR">REGULAR</option>
                                    </select>
                                </div>

                            ) : null}

                            <br />

                            <div style={{ textAlign: 'left' }}>
                                <Typography
                                    variant="title"
                                    gutterBottom
                                >Is this a free admission ?  <span className="red-alert">*</span></Typography>
                                <select
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.is_free_admission}
                                    name="is_free_admission"
                                >
                                    <option value="">Choose</option>
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                </select>
                            </div>

                        </CardContent>

                        {this.state.isLoaded ? (<CardContent style={{ minHeight: '50vh' }}>
                            <Typography
                                variant="h6"
                            >
                                Final Choice
                            </Typography>




                            {this.state.course === 'TDC' ?

                                (<Grid container spacing={24}>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Combination of subjects Sought for TDC {this.state.stream} {this.state.course_type} Semester: {this.state.semester}  <span className="red-alert">*</span>
                                        </Typography>
                                    </Grid>


                                    {this.state.stream === 'ARTS' && this.state.course_type === 'HONOURS' ? null : (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['compulsory_1'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['compulsory_1']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Compulsory Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel


                                            value={this.state.compulsory_1}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_1')}
                                        />

                                    </Grid>)}

                                    <Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['compulsory_2'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['compulsory_2']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Compulsory Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel

                                            value={this.state.compulsory_2}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_2')}
                                        />

                                    </Grid>

                                    {this.state.stream === 'SCIENCE' && this.state.course_type === 'REGULAR' ? (
                                        <Grid item xs={12} sm={12} lg={4} md={4}>
                                            {this.state.errors['compulsory_3'] !== '' ? (

                                                <div>
                                                    <Typography
                                                        variant="caption"
                                                        style={{ color: 'red' }}
                                                    >
                                                        {this.state.errors['compulsory_3']}
                                                    </Typography>
                                                </div>

                                            ) : null}

                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Compulsory Subject <span className="red-alert">*</span>
                                            </Typography>
                                            <Sel

                                                value={this.state.compulsory_3}
                                                isLoading={!this.state.isLoaded}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.onSubChange.bind(this, 'compulsory_3')}
                                            />

                                        </Grid>
                                    ) : null}


                                    {this.state.stream === 'COMMERCE' && this.state.semester == 3 ? (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['mil'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['mil']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            MIL Subjects <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.mil_list}
                                            value={this.state.mil}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'mil')}
                                        />

                                    </Grid>) : null}

                                    {this.state.stream === 'ARTS' ? (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['mil'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['mil']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            MIL Subjects <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.mil_list}
                                            value={this.state.mil}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'mil')}
                                        />

                                    </Grid>) : null}

                                    {this.state.stream === 'COMMERCE' ? (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['compulsory_3'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['compulsory_3']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Compulsory <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel

                                            value={this.state.compulsory_3}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_3')}
                                        />

                                    </Grid>) : null}

                                    {this.state.stream === 'COMMERCE' ? (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['generic_1'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['generic_1']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Compulsory Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel

                                            value={this.state.generic_1}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'generic_1')}
                                        />

                                    </Grid>) : null}

                                    {this.state.stream === 'COMMERCE' && this.state.course_type === 'HONOURS' ? (<Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['major_1'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['major_1']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <Typography
                                            variant="subtitle2"
                                        >
                                            Special Paper <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.major_list}
                                            value={this.state.major_1}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'major_1')}
                                        />

                                    </Grid>) : null}


                                </Grid>) : null}



                            {this.state.course === 'TDC' && this.state.course_type === 'HONOURS' && this.state.stream !== 'COMMERCE' ? (<Grid container spacing={24}>

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    {this.state.errors['major_1'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['major_1']}
                                            </Typography>
                                        </div>

                                    ) : null}


                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Major Subject <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        options={this.state.major_list}
                                        value={this.state.major_1}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'major_1')}
                                    />

                                </Grid>





                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['generic_1'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['generic_1']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Generic Subject  <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        options={this.state.generic_list}
                                        value={this.state.generic_1}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'generic_1')}
                                    />

                                </Grid>




                            </Grid>) : null}




                            {this.state.course === 'TDC' && this.state.course_type === 'REGULAR' && this.state.stream !== 'COMMERCE' ? (<Grid container spacing={24}>


                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['generic_1'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['generic_1']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        C C 1 <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        options={this.state.generic_list}
                                        value={this.state.generic_1}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'generic_1')}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['generic_2'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['generic_2']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        C C 2 <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        options={this.state.generic_list}
                                        value={this.state.generic_2}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'generic_2')}
                                    />

                                </Grid>

                                {/* <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['generic_3'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['generic_3']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        C C 3 <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        options={this.state.generic_list}
                                        value={this.state.generic_3}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'generic_3')}
                                    />

                                </Grid> */}


                            </Grid>) : null}




                            {this.state.course === 'BSC-BIOTECHNOLOGY' || this.state.course === 'BCA' ? (<Grid container spacing={24}>


                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['compulsory_1'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['compulsory_1']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Compulsory Subject <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel

                                        value={this.state.compulsory_1}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'compulsory_1')}
                                    />

                                </Grid>
                            </Grid>) : null}

                                        

                            {this.state.course === 'PG' ? (<Grid container spacing={24}>


                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    {this.state.errors['compulsory_1'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['compulsory_1']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Compulsory Subject <span className="red-alert">*</span>
                                    </Typography>
                                    <Sel
                                        value={this.state.compulsory_1}
                                        options={this.state.compulsory_list}
                                        isLoading={!this.state.isLoaded}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.onSubChange.bind(this, 'compulsory_1')}
                                    />

                                </Grid>
                            </Grid>) : null}


                            {this.state.course === 'HS' ?
                                (<Grid container spacing={24}>
                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Combination of subjects Sought for HS Course <span className="red-alert">*</span>
                                        </Typography>
                                    </Grid>



                                    <Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['compulsory_1'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['compulsory_1']}
                                                </Typography>
                                            </div>

                                        ) : null}


                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Compulsory Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.compulsory_list}
                                            value={this.state.compulsory_1}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_1')}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={4} md={4}>

                                        {this.state.errors['mil'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['mil']}
                                                </Typography>
                                            </div>

                                        ) : null}


                                        <Typography
                                            variant="subtitle2"

                                        >
                                            MIL Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.mil_list}
                                            value={this.state.mil}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'mil')}
                                        />

                                    </Grid>


                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                        {this.state.errors['subject_comb'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['subject_comb']}
                                                </Typography>
                                            </div>

                                        ) : null}

                                        <br />

                                        <FormControl variant="outlined" required className={classes.formControl}>
                                            <InputLabel>
                                                Subject Combination
                                            </InputLabel>
                                            <Select
                                                value={this.state.subject_comb}
                                                onChange={this.onChange}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={200}
                                                        name="subject_comb"

                                                    />
                                                }
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {i}
                                            </Select>
                                        </FormControl>

                                        {/* <MultiSubject

                                            subjects={this.state.f_subject_list}
                                            nos={4}
                                            setData={this.setSubjectData.bind(this, 'optional_subjects')}
                                            data={this.state.optional_subjects}
                                            edit={this.props.edit}

                                        /> */}



                                    </Grid>


                                </Grid>) : null

                            }
                        </CardContent>) : null}


                        <CardContent>
                            <Typography
                                variant="title"
                            >
                                Roll No <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <input
                                className="form-control"
                                name="roll_no"
                                required
                                value={this.state.roll_no}
                                onChange={this.onChange}
                                placeholder="Roll No"
                            />
                        </CardContent>

                        <CardContent>


                            <ExpansionPanel expanded={this.state.isExpanded} onChange={this.handlePanelChange}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography
                                        variant="title"
                                    >
                                        Edit Details if Necessary
                            </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Full Name <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.onChange}
                                                required

                                            />
                                        </Grid>




                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Phone <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="phone"

                                                required
                                                value={this.state.phone}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Email <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="email"

                                                required
                                                value={this.state.email}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Father's Name <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                name="father_name"
                                                value={this.state.father_name}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Mother's Name <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                name="mother_name"
                                                value={this.state.mother_name}
                                                onChange={this.onChange}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Parent's Occupation <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                name="p_occupation"
                                                value={this.state.p_occupation}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Gender <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="gender"
                                                required
                                                value={this.state.gender}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="MALE">MALE</option>
                                                <option value="FEMALE">FEMALE</option>
                                                <option value="OTHER">OTHER</option>
                                            </select>
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Date of Birth <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="date"
                                                required
                                                name="date_of_birth"
                                                value={this.state.date_of_birth}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Nationality <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                name="nationality"
                                                value={this.state.nationality}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Religion <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                name="religion"
                                                value={this.state.religion}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Caste <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="caste"
                                                required
                                                value={this.state.caste}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="GENERAL">GENERAL</option>
                                                <option value="ST(H)">ST(H)</option>
                                                <option value="ST(P)">ST(P)</option>
                                                <option value="SC">SC</option>
                                                <option value="OBC">OBC</option>
                                                <option value="MOBC">MOBC</option>
                                            </select>
                                        </Grid>

                                        {/* <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Quota <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="quota"
                                        required
                                        value={this.state.quota}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select Quota</option>
                                        <option value="None">None</option>
                                        <option value="Sports">Sports</option>
                                        <option value="Cultural">Cultural</option>
                                        <option value="NCC">NCC</option>
                                        <option value="Scouts and Guides">Scouts and Guides</option>
                                        <option value="Other State">Other State</option>
                                        <option value="International">International</option>
                                        <option value="NSS">NSS</option>
                                    </select>
                                </Grid> */}

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                State <span className="red-alert">*</span>
                                            </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="state"
                                                required
                                                value={this.state.state}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Blood Group
                                    </Typography>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="blood_group"
                                                value={this.state.blood_group}
                                                onChange={this.onChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Present Address <span className="red-alert">*</span>
                                            </Typography>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                rows="4"
                                                required
                                                name="present_address"
                                                value={this.state.present_address}
                                                onChange={this.onChange}
                                            ></textarea>
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Permanent Address <span className="red-alert">*</span> &nbsp;&nbsp;

                                            </Typography>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                rows="4"
                                                required
                                                name="permanent_address"
                                                value={this.state.permanent_address}
                                                onChange={this.onChange}
                                            ></textarea>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Local Guardians  <span className="red-alert">*</span>
                                            </Typography>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                rows="4"
                                                required
                                                name="local_g"
                                                value={this.state.local_g}
                                                onChange={this.onChange}
                                            ></textarea>
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Extra Curricular Activity
                                    </Typography>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                rows="4"
                                                name="extra_curr"
                                                value={this.state.extra_curr}
                                                onChange={this.onChange}
                                            ></textarea>
                                        </Grid>

                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </CardContent>


                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            {!this.state.isSubmitted ? <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                            >
                                Final Submit
                                </Button> : null}
                        </CardActions>
                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(ChoiceFillUp)