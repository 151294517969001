import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import { isMobile } from "react-device-detect";
import { PostData } from '../../../api/service';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }


    getStatus(status) {
        if (status === 1) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile(blob) {
        if (!isMobile) {
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:" + blob.mime + ";base64, " + encodeURI(blob.data) + "'></iframe>")
        } else {
            var blob1 = this.b64toBlob(blob.data, blob.mime);
            var blobUrl = URL.createObjectURL(blob1);

            if (blob.mime === 'application/pdf') {
                var link = document.createElement('a');
                link.href = blobUrl;
                link.download = `application.pdf`;
                link.click();
            } else {
                this.setState({
                    blobUrl,
                    blobLoad: true
                })
            }
        }
    }

    downloadFile = (el) => {
        PostData(`/getfiledata`, el)
            .then((resp) => {
                this.showFile(resp)
            })
    }

    showFiles = (application_file) => {
        let i = [];
        if(Array.isArray(application_file) && application_file.length > 0){
            application_file.map((el,index) => {
                i.push(<li key={index}><div style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => this.downloadFile(el)}>{el.path}</div></li>)
            })
        }
        return <ul>{i}</ul>;
    }


    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Accounts Head",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Accounts Sub Head",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Entry Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Voucher No",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Narration",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Amount",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Sanction Letter",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "Letters",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            customToolbar: () => {
                return (
                    <IconButton
                        onClick={this.props.onAddClick}>
                    <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    index + 1, 
                    el.type,
                    el.head_name,
                    el.sub_head_name, 
                    el.entry_date, 
                    el.vcr_no, 
                    el.narration, 
                    el.amount, 
                    el.sanction_no, 
                    this.showFiles(el.application_file),
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"CashBook Entry"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
