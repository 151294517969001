import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const styles = theme => ({

})

class ViewTableShort extends Component {
    render() {
        return (
            <div>
                <TableData
                    data={this.props.data}
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ViewTableShort);



class TableData extends Component {

    
    render() {
        return (
            <div>

                <div style={{textAlign: 'center'}}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={`Merit_list_${this.props.course}_${this.props.stream}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                </div>

                

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Merit List, <b>2019-2020</b>
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Course: <b>{this.props.course}</b> &nbsp;&nbsp; Stream: <b>{this.props.stream}</b>
                                </Typography>

                                {this.props.course === 'TDC' ? (<Typography
                                    variant="body1"
                                >
                                    Course Type: <b>{this.props.course_type}</b>
                                </Typography>) : null}

                                {this.props.caste !== '' ? (<Typography
                                    variant="body1"
                                >
                                    Caste: <b>{this.props.caste}</b>
                                </Typography>) : null}
                            </td>

                            <td width="20%" align="center">
                                
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" className="table" id="table-to-xls">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Form No</td>
                            <td>Name</td>
                            <td>Marks</td>
                            <td>Percentage</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((el, index) =>

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.form_id}</td>
                                <td>{el.name} {el.university_roll_no != '' ? `University Roll No. ${el.university_roll_no}` : null}</td>
                                <td>{el.marks_obtained}</td>
                                <td>{el.percentage}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}