import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import CourseAdd from './RoutineAdd';
import { LinearProgress, Typography, Button, Grid, withStyles, Card, CardContent } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReportTable from './ReportTable';
import ReactToPrint from 'react-to-print';
import reactHtmlTableToExcel from 'react-html-table-to-excel';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2
    }
})

class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            heads: [],
            subheads: [],
            head_id: '',
            sub_head_id: '',
            start_date: '',
            end_date: '',
            f_subheads: [],
            edit: false,
            edit_data: [],
            isLoaded: false,
            isLoadData : false
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.name === 'head_id'){
            let subheads = this.state.subheads;
            let r = subheads.filter(el => el.head_id === parseInt(e.target.value));
            this.setState({
                f_subheads: r
            })
        }
    }

    __getData() {
        GetData(`/getaccountshead`)
            .then((resp) => {
                console.log(resp)
                this.setState({
                    heads: resp,
                })
            }).then(() => {
                GetData(`/getaccountssubhead`)
                    .then((resp) => {
                        console.log(resp)
                        this.setState({
                            subheads: resp,
                            isLoaded: true
                        })
                    })
            })
    }

    componentDidMount() {
        this.__getData();
    }

    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            head_id: this.state.head_id,
            sub_head_id: this.state.sub_head_id,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            type: this.state.sub_head_id === '' ? `head` : `subhead` 
        }

        PostData(`/${this.props.apikey}/${this.props.username}/getaccountreports`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isLoadData: true
                })
            })
    }
    render() {
        let { classes } = this.props;
        let heads1 = [];
        let subheads1 = [];
        if (this.state.isLoaded) {
            let heads = this.state.heads;
            if (Array.isArray(heads) && heads.length > 0) {
                heads1 = heads.map((el, index) =>
                    <option key={index} value={el.id}>{el.head_name}</option>
                )
            }

            let subheads = this.state.f_subheads;
            if (Array.isArray(subheads) && subheads.length > 0) {
                subheads1 = subheads.map((el, index) =>
                    <option key={index} value={el.id}>{el.sub_head_name}</option>
                )
            }
        }
        return (
            <div>

                <div className={classes.root}>


                    <Typography
                        variant="h6"
                    >
                        Accounts Reports
                    </Typography>

                    <form onSubmit={this.onSubmit}>
                        <Grid container spacing={24}>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <label>
                                    <Typography>
                                        Accounts Head
                                    </Typography>
                                </label>
                                <select
                                    className="form-control"
                                    placeholder="Select Head"
                                    name="head_id"
                                    value={this.state.head_id}
                                    onChange={this.onChange}
                                    required

                                >
                                    <option value="">Select Head</option>
                                    {heads1}
                                </select>
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <label>
                                    <Typography>
                                        Accounts Subhead
                                    </Typography>
                                </label>
                                <select
                                    className="form-control"
                                    placeholder="Select Sub Head"
                                    name="sub_head_id"
                                    value={this.state.sub_head_id}
                                    onChange={this.onChange}


                                >
                                    <option value="">Select Sub Head</option>
                                    {subheads1}
                                </select>
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <label>
                                    <Typography>
                                        Start Date
                                    </Typography>

                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="start_date"
                                    required
                                    value={this.state.start_date}
                                    onChange={this.onChange}

                                />
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <label>
                                    <Typography>
                                        End Date
                                    </Typography>


                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="end_date"
                                    required
                                    value={this.state.end_date}
                                    onChange={this.onChange}

                                />
                            </Grid>
                        

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                            <br /><br />
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </Grid>

                        </Grid>
                    </form>

                    <br />
                    {this.state.isLoadData && (this.state.data).length > 0 ?
                    (<Card>
                        <CardContent style={{ textAlign: 'center' }}>
                            <ReactToPrint
                                trigger={() =>
                                    <Button
                                        variant="outlined"
                                        color="primary"

                                    >
                                        Print
                                </Button>
                                }
                                ref={el => this.buttonRef = el}
                                content={() => this.componentRef}
                                pageStyle={{ margin: '10px' }}
                                copyStyles={true}
                            />


                            <reactHtmlTableToExcel
                                id="test-table-xls-button"
                                className={classes.button}
                                table="table-to-xls"
                                filename={`Receipt_${this.state.start_date}_${this.state.end_date}`}
                                sheet="tablexls"
                                buttonText="Download as XLS" />


                        </CardContent>
                        <CardContent>
                            <ReportTable
                                ref={el => (this.componentRef = el)}
                                data={this.state.data}
                                start_date={this.state.start_date}
                                end_date={this.state.end_date}
                                history={this.props.history}
                            />
                        </CardContent>

                    </Card>)
                    : null}

                </div>

            </div>
        )
    }
}

export default withStyles(styles)(Reports)