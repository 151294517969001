import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import Add from '@material-ui/icons/Add';
import Cloud from '@material-ui/icons/Cloud';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';



export default class LeaveTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
       
    }

    componentDidMount(){
        console.log()
    }


    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    onViewClick(el) {
        this.props.onViewClick(el);
    }

    goodDate(date) {
        let d = new Date(date);
        let month = parseFloat(d.getMonth() + 1);
        let dateFormat = d.getDate() + '-' + month + '-' + d.getFullYear();
        return dateFormat;
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return <span style={{ color: 'black', display: 'flex', alignItems: 'center' }}>Pending</span>

            case 1:
                return <span style={{ color: 'blue', display: 'flex', alignItems: 'center' }}>Received</span>

            case 2:
                return <span style={{ color: 'green', display: 'flex', alignItems: 'center' }}>Granted</span>

            case 3:
                return <span style={{ color: 'red', display: 'flex', alignItems: 'center' }}>Rejected</span>

            default:
                break;
        }
    }

    getStatusHod(status) {
        switch (status) {
            case 0:
                return <span style={{ backgroundColor: 'red', color: 'white', padding: '3px', borderRadius: '5px' }}>No</span>

            case 1:
                return <span style={{ backgroundColor: 'green', color: 'white', padding: '3px', borderRadius: '5px' }}>Yes</span>

            default:
                break;
        }
    }

    permission(action, ret){
        console.log(action)
    }


    render() {
        const columns = [
            {
                name: "Leave Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Start Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "End Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "No of days",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "HOD Consent",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Application Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "View",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            }
        };

        let data = [];
        let table_data = this.props.table_data;

        if (Array.isArray(table_data) && table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.leave_type, 
                    el.start_date, 
                    el.end_date, 
                    el.no_of_days, 
                    this.getStatusHod(el.is_approved_by_HOD), 
                    this.getStatus(el.action), 
                    <ZoomOutMap onClick={this.onViewClick.bind(this, el)} />,
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Leave Request List"}
                    data={data}
                    columns={columns}
                    options={options}
                
                />
            </div>
        )


    }
}
