import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import ProtectedRoutes from './ProtectedRoutes';
import LeaveContainer from './Containers/LeaveContainer';
import SettingsContainer from './Containers/SettingsContainer';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer';
import AccountsContainer from './Containers/AccountsContainer';
import LeaveApproveContainer from './Containers/LeaveApproveContainer';
import LeaveGrantContainer from './Containers/LeaveGrantContainer';



class App extends Component {
  
  render() {
    return (
      <Router history={history} >
        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />
            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/settings" component={SettingsContainer} />
            {/* <Route exact path="/attendance" component={AttendanceContainer} />
            <Route exact path="/takeattendance" component={AttendanceContainer} />
            <Route exact path="/editattendance" component={AttendanceContainer} />
            <Route exact path="/attendancereport" component={AttendanceContainer} /> */}
           {/*  <Route exact path="/feedback" component={FeedbackContainer} />
            <Route exact path="/feedback/:id" component={FeedbackContainer} /> */}

            {/* <Route exact path="/reportattendance" component={AttendanceReportContainer} />
            <Route exact path="/routine" component={RoutineContainer} />
            <Route exact path="/logbook" component={LogBookContainer} /> */}


            




            <Route exact path="/accounts" component={AccountsContainer} />
            <Route exact path="/accounts/accountheads" component={AccountsContainer} />
            <Route exact path="/accounts/accountsubheads" component={AccountsContainer} />
            <Route exact path="/accounts/payments" component={AccountsContainer} />
            <Route exact path="/accounts/opbalancehead" component={AccountsContainer} />
            <Route exact path="/accounts/opbalanceaccounts" component={AccountsContainer} />
            <Route exact path="/accounts/cashbook" component={AccountsContainer} />
            <Route exact path="/accounts/reports" component={AccountsContainer} />
            <Route exact path="/accounts/view" component={AccountsContainer} />

           
            <Route exact path="/leaveapprove" component={LeaveApproveContainer} />
            <Route exact path="/leavegrant" component={LeaveGrantContainer} />


            <ProtectedRoutes pp={this.props} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
