import React, { Component } from 'react'
import { withStyles, Typography, IconButton } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import Print from '@material-ui/icons/Print'
import Edit from '@material-ui/icons/Edit'

const styles = theme => ({

})

class ReportTable extends Component {
    render() {
        return (
            <div>
                <TableData
                    {...this.props}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ReportTable);



class TableData extends Component {

    
    
    render() {
        return (
            <div>

                

                <br />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/icon512.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Rangia College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Rangia, Kamrup
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                   Account Head Reports
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    
                                Starting Date: {this.props.start_date} | Ending Date: {this.props.end_date}

                            </Typography>
                            </td>
                            <td width="20%">
                                &nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" className="table" id="table-to-xls">
                    <thead>
                        <tr>
                            
                            <td>Entry Date</td>
                            <td>Head Name</td>
                            <td>Sub Head Name</td>
                            <td>Narration</td>
                            <td>Dr</td>
                            <td>Cr</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((el, index) =>

                            <tr key={index}>
                                <td>{el.entry_date}</td>
                                <td>{el.head_name}</td>
                                <td>{el.sub_head_name}</td>
                                <td>{el.narration}</td>
                                <td>{el.dr}</td>
                                <td>{el.cr}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}