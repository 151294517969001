import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button, Tooltip } from '@material-ui/core';

import BestOfThree from './BestOfThree';

import { blue, orange, lightGreen } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import HelpOutline from '@material-ui/icons/HelpOutline'

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class EditMarks extends Component {

    state = {
        form_id: '',
        course: '',
        stream: '',
        no_combo_course: 0,
        best_subjects: [],
        last_marks: [],
        total_marks: '',
        marks_obtained: '',
        percentage: '',
        last_exam_passed: '',
        university_roll_no: '',
        ranking_score: '',
        ranking_serial: '',
        errors: [],
        bot: 3,
        mob: 5,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props.data)
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0]
            }, () => {
                if (this.state.course === 'HS') {
                    this.setState({
                        course_type: ''
                    })
                }
            })
        }

    }

    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;

            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0]
                }, () => {
                    if (this.state.course === 'HS') {
                        this.setState({
                            course_type: ''
                        })
                    }
                })
            }
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    onNewChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }



    handleValidation = () => {
        let { course, stream, exam_passed, best_subjects, last_marks, certificate, marksheet, errors } = this.state;
        let isValid = true;

        

        /* if (Array.isArray(last_marks) && last_marks.length === 0) {
            isValid = false;
            errors['last_marks'] = 'Please fill up this field'
        } else {
            errors['last_marks'] = '';
        }

        

        
        if (course === 'TDC') {
            if (Array.isArray(best_subjects) && best_subjects.length === 0) {
                isValid = false;
                errors['best_subjects'] = 'Please fill up this field'
            } else {
                errors['best_subjects'] = '';
            }
        } */

        this.setState({
            errors
        })

        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state)


        if (this.handleValidation()) {
            let d = {
                form_id: this.state.form_id,
                last_marks: this.state.last_marks,
                best_subjects: this.state.best_subjects,
                total_marks: this.state.total_marks,
                marks_obtained: this.state.marks_obtained,
                percentage: this.state.percentage,
                last_exam_passed: this.state.last_exam_passed,
                university_roll_no: this.state.university_roll_no,
                ranking_score: this.state.ranking_score,
                ranking_serial: this.state.ranking_serial

            };
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.props.setData(d)
                    },
                    {
                        label: 'No'
                    }
                ]
            });

        }



    }

    sentTotalData = (data) => {
        this.setState({
            total_marks: data.over_all,
            marks_obtained: data.obtained,
            percentage: data.percentage
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <div className={classes.redStrip}></div>
                        <CardHeader
                            title={`Application Form`}
                            subheader={`Educational Details ${this.state.form_id}`}
                        />

                        <CardContent>




                            <Grid container spacing={24}>

                                {this.state.course === 'TDC' ? <Grid item xs={12} sm={12} lg={12} md={12}>
                                    {this.state.errors['best_subjects'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['best_subjects']}
                                            </Typography>
                                        </div>

                                    ) : null}
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Best of Three Subjects <span className="red-alert">*
                                        <Tooltip title={
                                                `Please mention the full Name of the subject`
                                            }>
                                                <HelpOutline
                                                    style={{
                                                        fontSize: 15,
                                                        color: 'grey'
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    </Typography>

                                    <BestOfThree
                                        onNewChange={this.onNewChange.bind(this, 'best_subjects')}
                                        type={0}
                                        rows={3}
                                        data={this.state.best_subjects}
                                    />

                                </Grid> : null}

                            </Grid>


                            <br />


                            <br />

                            <Grid container spacing={24}>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    {this.state.errors['last_marks'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['last_marks']}
                                            </Typography>
                                        </div>

                                    ) : null}
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Marks Obtained in last examination passed <span className="red-alert">*</span>
                                    </Typography>

                                    {this.state.course === 'HS' ? (<BestOfThree
                                        onNewChange={this.onNewChange.bind(this, 'last_marks')}
                                        type={1}
                                        sentTotalData={this.sentTotalData.bind(this)}
                                        rows={6}
                                        data={this.state.last_marks}
                                    />) : (
                                            <BestOfThree
                                                onNewChange={this.onNewChange.bind(this, 'last_marks')}
                                                type={1}
                                                sentTotalData={this.sentTotalData.bind(this)}
                                                rows={5}
                                                data={this.state.last_marks}
                                            />
                                        )}

                                </Grid>



                            </Grid>

                            <br />
                            <br />

                            <Grid container spacing={24}>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        University Roll No <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="university_roll_no"
                                        value={this.state.university_roll_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Last examination Passed <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="last_exam_passed"
                                        value={this.state.last_exam_passed}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Ranking Serial <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="ranking_serial"
                                        value={this.state.ranking_serial}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Ranking Score <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="ranking_score"
                                        value={this.state.ranking_score}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                </Grid>


                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Update
                            </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(EditMarks)