import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onButtonClick = (id) => {
        this.props.onButtonClick(id);
    }


    getStatus(status) {
        if (status === 1) {
            return 'Yes';
        } else {
            return 'No';
        }
    }


    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Bank Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Bank Account",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Bank Branch",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Bank IFSC",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            customToolbar: () => {
                return (
                    <IconButton
                        onClick={this.props.onAddClick}>
                    <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index + 1, el.bank_name, el.bank_account, el.bank_branch, el.bank_ifsc,<Launch onClick={this.onActionClick.bind(this, el)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Payments"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
