import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import { Switch, Route } from 'react-router-dom'
import LeaveApply from '../Components/Leave/LeaveApply';
import LeaveStatus from '../Components/Leave/LeaveStatus';
import LeaveApprove from '../Components/Principal/LeaveApprove';

class LeaveContainer extends Component {

    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;

        this.state = {
            username: us.user.id,
            apikey: us.user.apikey,
            id: us.user.id
        }
        
    }


    render() {
        return (
            <div>
                <Navbar history={this.props.history} title="DashBoard" breadcumb="HOME / DashBoard" username={this.state.username} />
                <div className="main-body">

                    <Switch>
                        <Route exact path="/leave/apply" render={(props) => <LeaveApply {...props} {...this.props} history={this.props.history} username={this.state.username} apikey={this.state.apikey} />} />
                        <Route exact path="/leave/status" render={(props) => <LeaveStatus {...props} {...this.props} username={this.state.username} apikey={this.state.apikey} />} />
                        <Route exact path="/leave/approve/:action" render={(props) => <LeaveApprove {...props} {...this.props} username={this.state.username} apikey={this.state.apikey} />} />
                    </Switch>

                </div>
            </div>
        )
    }
}

export default withAuth(LeaveContainer);