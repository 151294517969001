import React, { Component } from 'react'
import { withStyles, Card, CardContent, TextField, Button, CardHeader } from '@material-ui/core';
import Search from '@material-ui/icons/Search'
import { PostData } from './../../../api/service';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Snack from './../../Snackbar/Snack'
import FileViewer from './FileViewer';


const styles = theme => ({
    buttonSearch: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2
    }
})

class NewFileView extends Component {

    state = {
        form_id: '',
        isLoaded: false,
        active: 0,
        open: false,
        message: ''
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoaded: false
        })

        let d = {
            form_id: this.state.form_id
        }

        PostData(`/admissionform/getfilesofactivestudentsingle`, d)
            .then((resp) => {
                //console.log(resp);
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        email: resp[0].email,
                        isLoaded: true
                    })
                } else {
                    confirmAlert({
                        title: 'No data found',
                        message: '',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.setState({ form_id: '', email: '' })
                            }
                        ]
                    });
                }

            })


    }

    
    render() {
        let { classes } = this.props;

        

        
        return (
            <div>

                {this.state.open ?
                    (
                        <div>

                            <Snack
                                open={this.state.open}
                                message={this.state.message}
                                handleClose={() => {
                                    this.setState({
                                        open: false,
                                        message: ''
                                    })
                                }}
                            />

                        </div>
                    )
                    : null
                }
                <Card>
                    <CardHeader
                        title={`File Viewer`}
                    />
                    <CardContent style={{ textAlign: 'center' }}>
                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Form No."
                                type="number"
                                min="0"
                                name="form_id"
                                value={this.state.form_id}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <Button
                                type="submit"
                                className={classes.buttonSearch}
                                color="primary"
                            >
                                <Search />
                            </Button>


                        </form>
                    </CardContent>
                </Card>

                <br />

                {this.state.isLoaded ? (
                    <div>
                        <FileViewer
                            data={this.state.data}
                        />
                    </div>
                ) : null}

                
            </div>
        )
    }
}

export default withStyles(styles)(NewFileView)