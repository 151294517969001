import React, { Component } from 'react'
import { withStyles, Card, CardContent, TextField, Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search'
import { PostData } from './../../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactToPrint from 'react-to-print';

import Snack from './../../Snackbar/Snack'
import IdCardHolder from './IdCardHolder';



const styles = theme => ({
    buttonSearch: {
        marginTop: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2
    }
})

class IdCardFind extends Component {

    state = {
        roll_no: 'HS/2019/01',
        isLoaded: true,
        active: 0,
        open: false,
        message: '',
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoaded: false
        }, () => {
            this.setState({
                isLoaded: true
            })
        })

        

    }

    

    
    

    
    render() {
        let { classes } = this.props;

        let i = '';

        
        return (
            <div>

                {this.state.open ?
                    (
                        <div>

                            <Snack
                                open={this.state.open}
                                message={this.state.message}
                                handleClose={() => {
                                    this.setState({
                                        open: false,
                                        message: ''
                                    })
                                }}
                            />

                        </div>
                    )
                    : null
                }
                <Card>
                    <CardContent style={{ textAlign: 'center' }}>
                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Roll No."
                                type="text"
                                min="0"
                                name="roll_no"
                                value={this.state.roll_no}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <Button
                                type="submit"
                                className={classes.buttonSearch}
                                color="primary"
                            >
                                <Search />
                            </Button>


                        </form>
                    </CardContent>
                </Card>

                <br />

                <div align="center" style={{ width: '25vw' }}>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="outlined"
                                color="primary"

                            >
                                Print this Card
                        </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ width: '3.370in', height: '2.125in' }}
                        copyStyles={true}
                    />

                </div>

                <br />

                <div style={{ width: '25vw' }}>

                {this.state.isLoaded ? <IdCardHolder 
                    ref={el => (this.componentRef = el)}
                    roll_no={this.state.roll_no}
                 /> : null}

                </div>

               
            </div>
        )
    }
}

export default withStyles(styles)(IdCardFind)