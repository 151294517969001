import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';
import { GetData } from '../../../api/service';
import FileUpload from '../../FileUpload/FileUpload';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    footerButton: {
        padding: theme.spacing.unit
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            head: [],
            sub_head: [],
            f_sub_head: [],
            payments: [],
            isLoaded: false,
            id: '',
            type: '',
            head_id: '',
            sub_head_id: '',
            bank_id: '',
            entry_date: '',
            vcr_no: '',
            amount: '',
            chq: '',
            narration: '',
            sanction_no: '',
            application_file: [],
            open: false,
            message: '',
            error: '',
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState = () => {
        this.setState({
            id: '',
            type: '',
            head_id: '',
            sub_head_id: '',
            bank_id: '',
            entry_date: '',
            vcr_no: '',
            amount: '',
            chq: '',
            narration: '',
            error: ''
        })
    }

    __getInitialData = () => {

        GetData(`/getaccountshead`)
            .then((resp) => {
                //console.log(resp)

                GetData(`/getaccountssubhead`)
                    .then((rest) => {
                        //console.log(rest)   

                        GetData(`/${this.props.apikey}/${this.props.username}/getbanks`)
                            .then((res) => {
                                this.setState({
                                    head: resp,
                                    sub_head: rest,
                                    f_sub_head: rest,
                                    payments: res,
                                    isLoaded: true
                                })
                            })

                    })

            })

    }



    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'head_id') {
            let sub_head = this.state.sub_head;
            let r = sub_head.filter(el => el.head_id === parseInt(e.target.value));
            this.setState({
                f_sub_head: r
            })
        }
    }

    componentDidMount() {
        if (this.props.edit) {
            this.__getInitialData();
            this.setState({
                ...this.props.edit_data,
                head_id: this.props.edit_data.head_id,
                sub_head_id: this.props.edit_data.sub_head_id,
                bank_id: this.props.edit_data.bank_id,
            })
        } else {
            this.__getInitialState();
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.__getInitialData();
            if (this.props.edit) {

                this.setState({
                    ...this.props.edit_data,

                    head_id: this.props.edit_data.head_id,
                    sub_head_id: this.props.edit_data.sub_head_id,
                    bank_id: this.props.edit_data.bank_id,

                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        if(this.state.type == '' || this.state.head_id == '' || this.state.bank_id == '' || this.state.amount == '' || this.state.narration == ''){
            this.setState({
                error: 'Please fill up all the fields'
            })
        }else{
            const data = this.state;


            if (this.props.edit) {
                this.props.esetData(data);
            } else {
                this.props.setData(data);

            }

            this.__getInitialState();
        }

        

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onSetFile(e) {
        this.setState({
            application_file: e
        })
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let j = [];
        let k = [];
        if (this.state.isLoaded) {
            let head = this.state.head;
            if (Array.isArray(head) && head.length > 0) {
                i = head.map((el, index) =>
                    <MenuItem value={el.id} key={index}>{el.head_name}</MenuItem>
                )
            }

            let sub_head = this.state.f_sub_head;
            if (Array.isArray(sub_head) && sub_head.length > 0) {
                j = sub_head.map((el, index) =>
                    <MenuItem value={el.id} key={index}>{el.sub_head_name}</MenuItem>
                )
            }


            let payments = this.state.payments;
            if (Array.isArray(payments) && payments.length > 0) {
                k = payments.map((el, index) =>
                    <MenuItem value={el.id} key={index}>{el.bank_name}</MenuItem>
                )
            }
        }


        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>


                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    required
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="type"

                                        />
                                    }

                                >
                                    <MenuItem>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="receipt">Receipt</MenuItem>
                                    <MenuItem value="disbursement">Disbursement</MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel>Accounts Head</InputLabel>
                                <Select
                                    name="head_id"
                                    
                                    value={this.state.head_id}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="head_id"
                                        />
                                        
                                    }
                                    required
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {i}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl  variant="outlined" className={classes.formControl}>
                                <InputLabel>Accounts Sub Head</InputLabel>
                                <Select
                                    name="sub_head_id"
                                    value={this.state.sub_head_id}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="sub_head_id"
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {j}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel>Payment Options</InputLabel>
                                <Select
                                    name="bank_id"
                                    
                                    value={this.state.bank_id}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="bank_id"
                                        />
                                    }
                                    required
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {k}
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                variant="outlined"
                                label="Date"
                                name="entry_date"
                                className={classes.textField}
                                type="date"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.entry_date}
                                onChange={this.onChange}
                                required
                            />

                            <TextField

                                variant="outlined"
                                label="Voucher No"
                                name="vcr_no"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.vcr_no}
                                onChange={this.onChange}

                            />

                            <TextField
                                required
                                variant="outlined"
                                label="Amount"
                                name="amount"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.amount}
                                onChange={this.onChange}
                                required
                            />

                            <TextField

                                variant="outlined"
                                label="Cheque Details"
                                name="chq"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.chq}
                                onChange={this.onChange}

                            />

                            <TextField

                                variant="outlined"
                                label="Narration"
                                name="narration"
                                multiline
                                rows="7"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.narration}
                                onChange={this.onChange}
                                required
                            />

                            <TextField

                                variant="outlined"
                                label="Sanction Letter No."
                                name="sanction_no"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.sanction_no}
                                onChange={this.onChange}

                            />

                            <br />
                            <br />

                            <div style={{backgroundColor: '#f9f9f9', padding: '10px'}}>

                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    Upload Sanction Letter (if any)
                                </Typography>
                                <FileUpload
                                    dp={this.state.application_file}
                                    apikey={this.props.apikey}
                                    type="ACCOUNT_FILE"
                                    setDp={this.onSetFile.bind(this)}
                                />

                            </div>

                            <br />
                            <br />

                            <div style={{color: 'red'}}>
                                {this.state.error}
                            </div>

                            




                            <div className={classes.footerButton}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)