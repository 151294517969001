import React, { Component } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { withStyles, Card, CardContent, Typography } from '@material-ui/core';
import Report from '@material-ui/icons/Report';
import { lightBlue, red } from '@material-ui/core/colors';
import AdmissionDashBoard from './View/AdmissionDashBoard';
import NewAdmission from './New/NewAdmission';
import AdmissionReceipt from './View/AdmissionReceipt';
import AdmissionViewList from './View/AdmissionViewList';
import AdmissionViewListMajor from './View/AdmissionViewListMajor';
import AdmissionViewListShort from './View/AdmissionViewListShort';
import NewFileView from './View/NewFileView';
import AdmissionViewModified from './View/AdmissionViewModified';
import EditAdmission from './New/EditAdmission';
import PostAdmissionData from './View/PostAdmissionData';
import IdCardFind from './idcard/IdCardFind';
import NewAdmissionViewList from './View/NewAdmissionViewList';

const styles = theme => ({
    smallItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    ava: {
        width: 80,
        height: 80,
        backgroundColor: lightBlue[300],
        marginBottom: '8px',
    },
    icon: {
        fontSize: 35
    },
    noAccessDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: red[700]
    },
    noAccessIcon: {
        color: red[300]
    },
    navBarSmall: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 14,
        marginTop: '10vh',
        marginLeft: 240,
        backgroundColor: 'rgba(0,0,0,0.8)',
        height: '7vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '7vh',
            height: '10vh',
            overflowX: 'scroll',
        }
    },
    navBarItem: {
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&:hover': {
            color: 'blue'
        },
        borderRight: 'solid thin white',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    container: {
        paddingTop: '8vh'
    }
});


const newNavs = [
    {
        title: 'Dashboard',
        path: '/admission'
    },
    {
        title: 'New Admission',
        path: '/admission/new'
    },
    {
        title: 'Edit Admission',
        path: '/admission/edit'
    },
    {
        title: 'Admission View',
        path: '/admission/admissionview'
    },
    {
        title: 'View',
        path: '/admission/view'
    },
    {
        title: 'Major List',
        path: '/admission/major'
    },
    {
        title: 'Shortlist Generator',
        path: '/admission/shortlist'
    },
    {
        title: 'File viewer',
        path: '/admission/fileview'
    },
    {
        title: 'New Students',
        path: '/admission/newstudents'
    },
    {
        title: 'ID Card',
        path: '/admission/idcards'
    }
]

class AdmissionLanding extends Component {
    constructor(props) {
        super(props);

        let stat = false;

        let user = JSON.parse(localStorage.getItem('user'));
        let access = user.user.access;
        if (Array.isArray(access) && access.length > 0) {
            access.map((el, index) => {
                if (el.access_field === 'ADM') {
                    stat = true;
                }
            })
        }

        this.state = {
            access_egov: stat
        }
    }

    render() {
        let { classes } = this.props;
        if (this.state.access_egov) {
            return (
                <div>



                    <EGovNav {...this.props} />

                    <div className={classes.container}>
                        <Switch>
                            <Route exact path="/admission"
                                render={() => <AdmissionDashBoard
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/new"
                                render={() => <NewAdmission
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/edit"
                                render={() => <EditAdmission
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            

                            <Route exact path="/admission/view"
                                render={() => <AdmissionViewList
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/admissionview"
                                render={() => <NewAdmissionViewList
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />
                            
                            <Route exact path="/admission/major"
                                render={() => <AdmissionViewListMajor
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/shortlist"
                                render={() => <AdmissionViewListShort
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/fileview"
                                render={() => <NewFileView
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/admission/newstudents"
                                render={() => <PostAdmissionData
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />


                            <Route exact path="/admission/idcards"
                                render={() => <IdCardFind
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            

                        </Switch>
                    </div>



                    <br />

                </div>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardContent className={classes.noAccessDiv}>
                            <Report className={classes.noAccessIcon} />

                            <Typography
                                variant="body2"
                                color="inherit"
                            >
                                Sorry !! You don't have access to this feature
                        </Typography>
                        </CardContent>
                    </Card>
                </div>
            )
        }

    }
}

export default withStyles(styles)(AdmissionLanding);


export const EGovNav = (props) => {
    let { classes } = props;
    return (

        <div className={classes.navBarSmall}>
            {newNavs.map((el, index) =>

                <Link key={index} to={el.path}>
                    <div className={classes.navBarItem}>
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            {el.title}
                        </Typography>
                    </div>
                </Link>


            )}
        </div>

    )
}