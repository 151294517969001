import React, { Component } from 'react'
import { withStyles, Modal, Typography, IconButton, Grid, Button, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import Print from '@material-ui/icons/Print'
import Call from '@material-ui/icons/Call'
import Email from '@material-ui/icons/Email'
import ReactToPrint from "react-to-print";
import Cloud from '@material-ui/icons/Cloud';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import LeaveEdit from './LeaveEdit';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
        minHeight: '100vh'
    },
    pullRight: {
        float: 'right'
    },
    image: {
        width: '100%'
    },
    container:{
        padding: '1px',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]:{
            padding: '1px'
        }
    }
});


class LeaveEditModal extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Modal
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    className={classes.container}
                >   
                <div >
                       {this.props.open ? <LeaveEdit
                            open={this.props.open}
                            username={this.props.username}
                            apikey={this.props.apikey}
                            edit_data={this.props.edit_data}
                            handleClose={this.props.handleClose}
                            reloadList={this.props.reloadList}
                            handleDelete={this.props.handleDelete}
                            />: null}
                </div>
                    
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(LeaveEditModal);

