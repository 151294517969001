import React, { Component } from 'react'

import { GetData } from '../../../api/service';

export default class FileViewer extends Component {
    state = {
        visible: true,
        images: []
    }

    __getFile = (file_id, file_tag) => {
        GetData(`/${file_id}/getfilesingle`)
        .then((resp) => {
            //console.log(resp)
            let img = '';
            let downloadUrl = '';
            let images = this.state.images;
            if(resp.hasOwnProperty('data')){
                img = `data:${resp.mime};base64,${resp.data}`;
                //downloadUrl = `data:application/octet-stream;base64,${resp.data}`;
            }
            let d = {
                src: img,
                downloadUrl: downloadUrl,
                alt: file_tag
            }
            images.push(d)
            this.setState({
                images: images
            })
        })
    }

    componentDidMount() {
      // we have the files

        let data = this.props.data;

        if(Array.isArray(data.marksheet) && data.marksheet.length > 0){
            this.__getFile(data.marksheet[0].file_id, 'marksheet')
        }

        if (Array.isArray(data.certificate) && data.certificate.length > 0) {
            this.__getFile(data.certificate[0].file_id, 'certificate')
        }

        if (Array.isArray(data.bpl) && data.bpl.length > 0) {
            this.__getFile(data.bpl[0].file_id, 'bpl')
        }

        if (Array.isArray(data.income) && data.income.length > 0) {
            this.__getFile(data.income[0].file_id, 'income')
        }

        if (Array.isArray(data.income) && data.income.length > 0) {
            this.__getFile(data.tree[0].file_id, 'tree')
        }

        if (Array.isArray(data.dp) && data.dp.length > 0) {
            this.__getFile(data.dp[0].file_id, 'dp')
        }

        if (Array.isArray(data.signature) && data.signature.length > 0) {
            this.__getFile(data.signature[0].file_id, 'signature')
        }
    }
    
  render() {
    return (
      <div style={{zIndex: 100}}>
          
           
      </div>
    )
  }
}
