import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, Grid, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, CardHeader } from '@material-ui/core';
import { GetData, PostData } from '../../../api/service';
import ViewTable from './ViewTable';
import Condition from './Condition';
import ReactToPrint from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const styles = theme => ({
    formControl: {
        width: '100%'
    }
})

class AdmissionViewListMajor extends Component {

    state = {
        course: 'TDC',
        stream: '',
        preference: '',
        sub_code: '',
        sub_name: '',
        subjects: [],
        isSubjectLoaded: false,
        caste: '',
        data: [],
        isLoaded: false,

    }

    buttonRef = React.createRef();

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (e.target.name === 'course' || e.target.name === 'stream') {
                this.getSubjects(this.state.course, this.state.stream)
            }
        })

        if(e.target.name === 'sub_code'){
            let subjects = this.state.subjects;

            let r = subjects.filter(el => el.sub_code === e.target.value)
            if(Array.isArray(r) && r.length > 0){
                this.setState({
                    sub_name: r[0].sub_name
                })
            }
        }


    }

    getSubjects = (course, stream) => {
        if (course !== '' && stream !== '') {
            GetData(`/${course}/${stream}/getadmissionsubjectmajor`)
                .then((resp) => {
                    console.log(resp)
                    this.setState({
                        subjects: resp,
                        isSubjectLoaded: true
                    })

                })

        }
    }

    componentDidMount() {

    }


    onSubmit = (e) => {
        e.preventDefault();


        let d = {
            course: this.state.course,
            stream: this.state.stream,
            preference: this.state.preference,
            sub_code: this.state.sub_code,
            caste: this.state.caste
        }

        PostData(`/admissionform/meritlistmajor`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    setTable = (name, value) => {
        this.setState({
            [name]: value
        })
    }


    render() {
        let { classes } = this.props;
        let i = [];
        if (this.state.isSubjectLoaded) {
            let subjects = this.state.subjects;
            i = subjects.map((el, index) =>
                <MenuItem key={index} value={el.sub_code}>{el.sub_name}</MenuItem>
            )
        }
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            title={`Major List Generator`}
                        />
                        <CardContent>

                            <Grid container spacing={16}>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Course
                                        </InputLabel>
                                        <Select
                                            name="course"
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="course" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="TDC">TDC</MenuItem>
                                            <MenuItem value="PG">PG</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Stream
                        </InputLabel>
                                        <Select
                                            name="stream"
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="stream" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="ARTS">ARTS</MenuItem>
                                            <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                            <MenuItem value="COMMERCE">COMMERCE</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Preference
                    </InputLabel>
                                        <Select
                                            name="preference"
                                            value={this.state.preference}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="preference" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="1">Preference 1</MenuItem>
                                            <MenuItem value="2">Preference 2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Subject
                    </InputLabel>
                                        <Select
                                            name="sub_code"
                                            value={this.state.sub_code}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="sub_code" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {i}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Caste (Optional)
                    </InputLabel>
                                        <Select
                                            name="caste"
                                            value={this.state.caste}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="caste" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                                            
                                            <MenuItem value="ST(H)">ST(H)</MenuItem>
                                            <MenuItem value="ST(P)">ST(P)</MenuItem>
                                            <MenuItem value="SC">SC</MenuItem>
                                            <MenuItem value="OBC">OBC</MenuItem>
                                            <MenuItem value="MOBC">MOBC</MenuItem>


                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                                        </Button>
                        </CardActions>
                    </Card>
                </form>

                <br />




                {this.state.isLoaded ?
                    (<Card>
                        <CardContent style={{ textAlign: 'center' }}>
                            <ReactToPrint
                                trigger={() =>
                                    <Button
                                        variant="outlined"
                                        color="primary"

                                    >
                                        Print
                        </Button>
                                }
                                ref={el => this.buttonRef = el}
                                content={() => this.componentRef}
                                pageStyle={{ margin: '10px' }}
                                copyStyles={true}
                            />

                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="table-to-xls"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="Download as XLS" />

                            
                        </CardContent>
                        <CardContent>
                            <ViewTable
                                ref={el => (this.componentRef = el)}
                                data={this.state.data}
                                {...this.state}
                                />
                        </CardContent>

                    </Card>)
                    : null}
            </div>
        )
    }
}

export default withStyles(styles)(AdmissionViewListMajor)