import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';

class HomePage extends Component {
  constructor(props) {
    super(props);

    let us = JSON.parse(localStorage.getItem('user'));
    let username = us.user.email;

    this.state = {
      username: username,
      apikey: us.user.apikey,
      id: us.user.id,
      role: us.user.role,
      is_teaching: us.user.is_teaching,
    }

  }
  render() {
    return (
      <div>
        <Navbar history={this.props.history} username={this.state.username} is_teaching={this.state.is_teaching} />
        <div className="main-body">

          <Welcome
            history={this.props.history}
            username={this.state.username}
            apikey={this.state.apikey}
            role={this.state.role}
            id={this.state.id}
            is_teaching={this.state.is_teaching} />

        </div>
      </div>
    )
  }
}

export default withAuth(HomePage);