import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider, Card, CardContent, CardActions } from '@material-ui/core';
import { GetData } from '../../../api/service';

import AlertDiv from '../../AlertDiv';
import { lightGreen } from '@material-ui/core/colors';



const styles = theme => ({

})

class PreviewForm extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        this.setState({
            isLoaded: this.props.isLoaded
        })
    }



    render() {
        let { classes } = this.props;
        return (
            <div>

                <Card>
                    
                    {this.props.data.admission_done === 1 ? <CardContent>
                        <AlertDiv
                            back={lightGreen[100]}
                            border={lightGreen[400]}
                        >
                            <Typography>
                                Admission procedure for this Form No has been completed successfully. Please ask the student to proceed to fees counter for admission fees payment.
                            </Typography>
                        </AlertDiv>
                    </CardContent> : null}

                    {this.props.type == 1 ? <CardContent>
                        <AlertDiv
                            back={lightGreen[100]}
                            border={lightGreen[400]}
                        >
                            <Typography
                                variant="title"
                            >
                                Re-admission Application
                            </Typography>
                        </AlertDiv>
                    </CardContent> : null}

                    <CardContent>
                        {this.state.isLoaded ? <AdmissionForm
                            {...this.props.data}
                        /> : null}
                    </CardContent>

                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        {this.props.data.admission_done !== 1 ? <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.nextClick}
                        >
                            Proceed to Next Step
                            </Button> : null}
                    </CardActions>
                </Card>



            </div>
        )
    }
}

export default withStyles(styles)(PreviewForm);

class AdmissionForm extends Component {
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Admission Form, <b>2019-2020</b>
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />

                                  
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />



                <Grid container spacing={24}>

                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <Typography

                                        >
                                            Class to which admission is sougth
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        {this.props.course ? (
                                            <Typography
                                                variant="body2"
                                            >
                                                {this.props.course}-{this.props.stream} {this.props.course_type !== null ? this.props.course_type : ''}
                                            </Typography>
                                        ) : null}

                                        {this.props.course === 'HS' ? (
                                            <Typography
                                                variant="body2"
                                            >
                                                {this.props.course}-{this.props.stream}
                                            </Typography>
                                        ) : null}

                                    </td>
                                </tr>

                                <tr>
                                    <td width="40%">
                                        <Typography

                                        >
                                            {this.props.course === 'HS' ? 'Year' : 'Semester'}
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.semester}
                                        </Typography>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="40%">
                                        <Typography

                                        >
                                            Do you want to seek free admission ?
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.is_free_admission == 1 ? 'Yes' : 'No'}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr vAlign="bottom">
                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>
                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Marks Obtained
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.marks_obtained}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Tatal Marks
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.total_marks}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>

                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Percentage
                                                </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography>
                                                            {this.props.percentage}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3} align="center">
                        <div className="passport-photo">
                            <ImageViewer
                                data={this.props.dp}
                            />
                        </div>

                    </Grid>

                </Grid>




                <br />

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    Name of the Applicant
                                </Typography>
                            </td>
                            <td colSpan="3">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    1. Gender
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.gender}
                                </Typography>
                            </td>

                            <td width="20%">
                                <Typography>
                                    2. Date of Birth
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.date_of_birth}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    3. Nationality
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>

                            <td width="20%">
                                <Typography>
                                    4. Religion
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.religion}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    5. Caste
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.caste}
                                </Typography>
                            </td>

                            <td width="20%">
                                <Typography>
                                    6. Blood Group
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.blood_group}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    7. Mother's Name
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mother_name}
                                </Typography>
                            </td>

                            <td width="30%">
                                <Typography>
                                    Email:
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.email}
                                </Typography>
                            </td>


                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    8. Father's Name
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography>
                                    Phone No.
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    9. Parent's / Guardian's Occupation
                                </Typography>
                            </td>
                            <td width="30%" colSpan="3">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.p_occupation}
                                </Typography>
                            </td>
                        </tr>


                    </tbody>
                </table>

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Typography>
                                    10. Examination Passsed
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Board / University
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Roll &amp; No.
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Year
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Institution
                                </Typography>
                            </td>
                        </tr>
                        {this.props.exam_passed.map((el, index) =>

                            <tr key={index}>
                                <td align="center">{el.examination_passed}</td>
                                <td align="center">{el.board}</td>
                                <td align="center">{el.roll}</td>
                                <td align="center">{el.year}</td>
                                <td align="center">{el.institution}</td>
                            </tr>

                        )}
                    </tbody>

                </table>

                {this.props.course === 'TDC' ? (

                    <div>
                        <table className="table" width="100%">
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <Typography>
                                            11. Best of three Science / Arts / Commerce Subjects
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody>

                                <tr>
                                    <td>
                                        <Typography>
                                            Subjects
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) =>
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.subject}
                                            </Typography>
                                        </td>
                                    )}
                                    <td align="right">
                                        <Typography>
                                            Total
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Total Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        total_marks = parseFloat(el.total_marks) + total_marks;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.total_marks}
                                                </Typography>
                                            </td>
                                        )
                                    }

                                    )}
                                    <td align="right">
                                        <Typography>
                                            {total_marks}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Obtained Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.marks_secured}
                                                </Typography>
                                            </td>
                                        )
                                    }
                                    )}
                                    <td align="right">
                                        <Typography>
                                            {marks_secured}
                                        </Typography>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                ) : null}


                <div>
                    <table className="table" width="100%">
                        <tbody>
                            <tr>
                                <td colSpan={parseFloat(this.props.last_marks.length) + 2}>
                                    <Typography>
                                        12. Marks Secured in the last examination passed
                                        </Typography>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>

                            <tr>
                                <td>
                                    <Typography>
                                        Subjects
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) =>
                                    <td key={index} align="right">
                                        <Typography>
                                            {el.subject}
                                        </Typography>
                                    </td>
                                )}
                                <td align="right">
                                    <Typography>
                                        Total
                                        </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Total Marks
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    total_marks = parseFloat(el.total_marks) + total_marks;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.total_marks}
                                            </Typography>
                                        </td>
                                    )
                                }

                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.total_marks}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Obtained Marks
                                        </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.marks_secured}
                                            </Typography>
                                        </td>
                                    )
                                }
                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.marks_obtained}
                                    </Typography>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <br />
                <br />

                {this.props.course === 'TDC' ? (<table width="100%" className="table">

                    <tbody>
                        <tr>
                            <td colSpan="4">
                                <Typography>
                                    13. Combination of Subject sougth for TDC
                                </Typography>
                            </td>
                        </tr>

                        <tr>

                            <td >
                                <Typography>
                                    Compulsory: {this.props.compulsory_1.label}
                                </Typography>
                            </td>
                            <td>
                                <Typography>
                                    {this.props.compulsory_2.label}
                                </Typography>
                            </td>
                            <td>

                                {this.props.stream === 'ARTS' ? (
                                    <Typography>
                                        MIL: {this.props.mil.label}
                                    </Typography>
                                ) : null}

                                {this.props.stream === 'COMMERCE' ? (
                                    <Typography>
                                        {this.props.compulsory_3.label}
                                    </Typography>
                                ) : null}
                            </td>

                        </tr>

                    </tbody>


                    {this.props.course_type === 'HONOURS' ? (

                        <tbody>
                            {this.props.stream !== 'COMMERCE' ? (
                                <tr>
                                    <td>
                                        <Typography>
                                            Major
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            Preference 1: {this.props.major_1.label}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            Preference 2: {this.props.major_2.label}
                                        </Typography>
                                    </td>
                                </tr>
                            ) : (
                                    <tr>
                                        <td>
                                            <Typography>
                                                Generic Subject :  {this.props.generic_1.label}
                                            </Typography>
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                )}
                        </tbody>

                    ) : null}

                    {this.props.course_type === 'HONOURS' ? (

                        <tbody>
                            {this.props.stream !== 'COMMERCE' ? (
                                <tr>
                                    <td>
                                        <Typography>
                                            Generic
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            Preference 1: {this.props.generic_1.label}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            Preference 2: {this.props.generic_2.label}
                                        </Typography>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>

                    ) : null}

                    {this.props.course_type === 'REGULAR' ? (
                        <tbody>
                            {this.props.stream !== 'COMMERCE' ? (
                                <tr>
                                    <td>
                                        <Typography>
                                            CC Subject:    {this.props.generic_1.label}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            {this.props.generic_2.label}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            {this.props.generic_3.label}
                                        </Typography>
                                    </td>
                                </tr>
                            ) : (
                                    <tr>
                                        <td>
                                            <Typography>
                                                CC Subject:   {this.props.generic_1.label}
                                            </Typography>
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                )}

                        </tbody>
                    ) : null}













                </table>) : null}





                {this.props.course === 'HS' ? (

                    <table width="100%" className="table">

                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        14. Combination of Subjects sougth for HS Course
                                </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        variant="body2"
                                    >
                                        Compulsory: {this.props.compulsory_1.label}
                                    </Typography>
                                </td>

                                <td colSpan="2">
                                    <Typography
                                        variant="body2"
                                    >
                                        MIL: {this.props.mil.label}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <Grid container spacing={16}>
                                        {this.props.optional_subjects.map((el, index) =>

                                            <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                <Typography variant="body2" >
                                                    {index + 1} : {el.label}
                                                </Typography>
                                            </Grid>

                                        )}
                                    </Grid>
                                </td>
                            </tr>
                        </tbody>

                    </table>) : null}


                    {this.props.course === 'PG' ? (

                    <table width="100%" className="table">

                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <Typography>
                                        14. Subject Taken : <b>{this.props.compulsory_1.label}</b>
                                </Typography>
                                </td>
                            </tr>
                            
                           
                        </tbody>

                    </table>) : null}

                <br />


                <table className="table" width="100%">
                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    15.   Permanent Address
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.permanent_address}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography>
                                    16.   Present Address
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.present_address}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    17.   Local Guardian's Name
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.local_g}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography>
                                    18.   Extra-Curricular Activity
                                </Typography>
                            </td>
                            <td width="70%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.extra_curr}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>





            </div>
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%' }}

                /> : null}
            </div>
        )
    }
}