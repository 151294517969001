import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onButtonClick = (id, head_name) => {
        this.props.onButtonClick(id, head_name);
    }


    getStatus(status) {
        if (status === 1) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    getSubheadList(data, head_name) {
        let i = [];
        if (Array.isArray(data)) {
            i = data.map((el, index) =>

                <tr key={index}>
                    <td>{el.sub_head_name}</td>
                    <td>
                        <Button size="small" type="button" color="primary" onClick={this.onButtonClick.bind(this, el, head_name)}><Launch /> Go to legder</Button>
                    </td>
                    <td>
                   <Launch onClick={this.onActionClick.bind(this, el)} />
                    </td>
                </tr>

            )

        }

        return <table><tbody>{i}</tbody></table>;
    }






    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Accounts Head",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Accounts Sub Head",
                options: {
                    filter: true,
                    sort: true,
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            customToolbar: () => {
                return (
                    <IconButton
                        onClick={this.props.onAddClick}>
                        <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    index + 1,
                    el.head_name,
                    this.getSubheadList(el.sub_head, el.head_name)
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Accounts Sub Head"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
