import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }


    getStatus(status) {
        if (status === 1) {
            return 'Yes';
        } else {
            return 'No';
        }
    }


    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Finanacial Year",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "is_active",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            customToolbar: () => {
                return (
                    <IconButton
                        onClick={this.props.onAddClick}>
                    <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index + 1, el.financial_year, this.getStatus(el.is_active), <Launch onClick={this.onActionClick.bind(this, el)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Payments"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
