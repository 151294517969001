import React, { Component } from 'react'
import { withStyles, Card, CardHeader, CardContent, TextField, Button } from '@material-ui/core';
import Search from '@material-ui/icons/Search'
import CashTable from './CashTable';
import { GetData } from '../../../api/service';
import CashBookTable from './CashBookTable';

const styles = theme => ({
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'

    },
    button: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    }
})

class CashBookView extends Component {
    state = {
        id: '',
        head_name: '',
        sub_head_name: '',
        account_type: '',
        isLoaded: false,
        result: []
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        let data = this.props.history.location.state;

        console.log(data)

        this.setState({
            ...data,
           
        }, () => this.__getExternalData())
    }

    __getExternalData = () => {
        GetData(`/${this.props.apikey}/${this.props.username}/${this.state.head_id}/${this.state.id}/${this.state.account_type}/getledgeraccount`)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    result: resp,
                    isLoaded: true
                })
            })
    }


    onSubmit = (e) => {
        e.preventDefault();

    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                {}

                <br />

                
                        {this.state.isLoaded ? <CashBookTable
                            isLoaded={this.state.isLoaded}
                            table_data={this.state.result}
                            title={this.state.head_name}
                            subheader={this.state.sub_head_name}
                        /> : 'Loading....'}
                    
            </div>
        )
    }
}

export default withStyles(styles)(CashBookView);


