import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';

const styles = theme => ({

})

class CashTable extends Component {
    render() {
        let { classes } = this.props;
        let i = [];
        if(this.props.isLoaded === true){
            let result = this.props.result;
            if(Array.isArray(result)){
                if(result.length > 0){
                    i = result.map((el,index) => 
                        <tr key={index}>
                            <td>{el.entry_date}</td>
                            <td>{el.vcr_no}</td>
                            <td>{el.narration}</td>
                            <td>{el.cr}</td>
                            <td>{el.dr}</td>
                        </tr>
                    )
                }else{
                    i = <tr key={0}>
                        <td colSpan="5" align="center">No entries found</td>
                    </tr>
                }
            }
        }
        return (
            <div>
                <table width="100%">
                    <thead>
                        <tr>
                            <th>ENTRY DATE</th>
                            <th>VCR NO</th>
                            <th>NARRATION</th>
                            <th>CR</th>
                            <th>DR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {i}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withStyles(styles)(CashTable)