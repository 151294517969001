import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        borderRadius: `5px`,
        padding: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    }
})

class AlertDiv extends Component {
    render() {
        let { classes } = this.props;
        const style = {
            backgroundColor: this.props.back,
            border: `solid thin ${this.props.border}`,
        }
        return (
            <div className={classes.root} style={style}>
                {this.props.children}
            </div>
        )
    }
}

export default withStyles(styles)(AlertDiv)