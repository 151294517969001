import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import { GetData } from '../../../api/service';
import AdmissionReceiptTable from './AdmissionReceiptTable';
import ReceiptPrint from './../../Fees/New/ReceiptPrint';

const styles = theme => ({

})

class AdmissionReceipt extends Component {

    state = {
        data: [],
        isLoaded: false,
        print: false,
        receipt_no: ''
    }

    __loadData = () => {
        GetData(`/getreceipts`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__loadData();
    }

    onPrintClick = (receipt_no) => {
        this.setState({
            receipt_no: receipt_no,
            print: true,
            
        })
    }

    onEditClick = (el) => {
       this.props.history.push('/fees/editreceipt', el)
    }
    
    render() {
        let { classes } = this.props;
        return (
            <div>
                {this.state.isLoaded ? (

                    <AdmissionReceiptTable
                        table_data={this.state.data}
                        onPrintClick={this.onPrintClick.bind(this)}
                        onEditClick={this.onEditClick.bind(this)}
                    />

                ) : null}

                <br />

                {this.state.print ? <Button
                    variant="text"
                    onClick={() => {
                        this.setState({
                            receipt_no: '',
                            print: false
                        })
                    }}
                >
                    Close Print Box
                </Button> : null}
                <br />
                <br />

                {this.state.print ? (

                    <ReceiptPrint
                        receipt_no={this.state.receipt_no}
                        
                    />

                ) : null}
            </div>
        )
    }
}

export default withStyles(styles)(AdmissionReceipt)