import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, Grid, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, CardHeader, Typography } from '@material-ui/core';
import { GetData, PostData } from '../../../api/service';
import ViewTable from './ViewTable';
import Condition from './Condition';
import ReactToPrint from 'react-to-print';
import ViewTableNew from './ViewTableNew';
import ViewTableNewAdmission from './ViewTableNewAdmission';

const styles = theme => ({
    formControl: {
        width: '100%'
    }
})

class NewAdmissionViewList extends Component {

    state = {
        course: '',
        stream: '',
        course_type: '',
        semester: '',
        data: [],
        isLoaded: false,

    }

    buttonRef = React.createRef();

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

  

       

        let d = {
            course: this.state.course,
            stream: this.state.stream,
            semester: this.state.semester,
            course_type: this.state.course_type
        }

        PostData(`/admissionform/studentlist`, d)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    setTable = (name, value) => {

        this.setState({
            [name]: value
        })
    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            title={`Admission View List`}
                        />
                        <CardContent>

                            <Grid container spacing={16}>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Course
                                        </InputLabel>
                                        <Select
                                            name="course"
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="course" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            <MenuItem value="HS">HS</MenuItem>
                                            <MenuItem value="TDC">TDC</MenuItem>
                                            <MenuItem value="PG">PG</MenuItem>
                                            <MenuItem value="BSC-BIOTECHNOLOGY">BSC-BIOTECHNOLOGY</MenuItem>
                                            <MenuItem value="BCA">BCA</MenuItem>


                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Stream
                        </InputLabel>
                                        <Select
                                            name="stream"
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="stream" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="ARTS">ARTS</MenuItem>
                                            <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                            <MenuItem value="COMMERCE">COMMERCE</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                {this.state.course === 'TDC' ? (<Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Course Type
                    </InputLabel>
                                        <Select
                                            name="course_type"
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="course_type" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HONOURS">HONOURS</MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>
                                            <MenuItem value="MAJOR">MAJOR</MenuItem>
                                            <MenuItem value="GENERAL">GENERAL</MenuItem>


                                        </Select>
                                    </FormControl>
                                </Grid>) : null}

                                <Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            {this.state.course == 'HS' ? 'Year' : 'Semester'} 
                    </InputLabel>
                                        <Select
                                            name="semester"
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="semester" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {[1,2,3,4,5,6].map((el,index) => 
                                                    <MenuItem key={index} value={el}>{el}</MenuItem>
                                                )}


                                        </Select>
                                        <Typography variant="caption">
                                                (Do not select any semester / year if searching for newly applied candidates)
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                                        </Button>
                        </CardActions>
                    </Card>
                </form>

                <br />




                {this.state.isLoaded ?
                    (<Card>
                        <CardContent style={{ textAlign: 'center' }}>
                            <ReactToPrint
                                trigger={() =>
                                    <Button
                                        variant="outlined"
                                        color="primary"

                                    >
                                        Print
                        </Button>
                                }
                                ref={el => this.buttonRef = el}
                                content={() => this.componentRef}
                                pageStyle={{ margin: '10px' }}
                                copyStyles={true}
                            />

                            
                        </CardContent>
                        <CardContent>
                            <ViewTableNewAdmission
                                ref={el => (this.componentRef = el)}
                                data={this.state.data}
                                {...this.state}
                            />
                        </CardContent>

                    </Card>)
                    : null}
            </div>
        )
    }
}

export default withStyles(styles)(NewAdmissionViewList)