import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    footerButton: {
        padding: theme.spacing.unit
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            bank_account: '',
            head_id: '',
            payment_to: '',
            date: '',
            particulars: '',
            amount: '',
            mop: '',
            payment: [],
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState = () => {
        this.setState({
            id: '',
            financial_year: '',
            is_active: ''
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.__getInitialState();
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>


                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Bank Account</InputLabel>
                                <Select
                                    name="is_active"
                                    value={this.state.is_active}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="is_active"
                                        />
                                    }
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Not Active</MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Account Head</InputLabel>
                                <Select
                                    name="is_active"
                                    value={this.state.is_active}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="is_active"
                                        />
                                    }
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Not Active</MenuItem>
                                </Select>
                            </FormControl>


                            <TextField
                                variant="outlined"
                                label="Date"
                                name="financial_year"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.financial_year}
                                onChange={this.onChange}
                            />



                            <TextField
                                variant="outlined"
                                label="To"
                                name="financial_year"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.financial_year}
                                onChange={this.onChange}
                            />


                            <TextField
                                variant="outlined"
                                label="Particulars"
                                name="financial_year"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.financial_year}
                                onChange={this.onChange}
                            />



                            

                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Is Active ?</InputLabel>
                                <Select
                                    name="is_active"
                                    value={this.state.is_active}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="is_active"
                                        />
                                    }
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Not Active</MenuItem>
                                </Select>
                            </FormControl>






                            <div className={classes.footerButton}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)