import React, { Component } from 'react';
import withAuth from '../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import AdmissionLanding from '../Components/Admission/AdmissionLanding';
import LeaveApproveLanding from '../Components/LeaveApprove/LeaveApproveLanding';

class LeaveApproveContainer extends Component {
    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;
        let name = us.user.name;

        this.state = {
            username: us.user.id,
            apikey: us.user.apikey,
            name: name
        }

    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} username={this.state.username} apikey={this.state.apikey} />
                <div className="main-body">
                    <LeaveApproveLanding
                        history={this.props.history}
                        username={this.state.username}
                        name={this.state.name}
                        apikey={this.state.apikey} />
                </div>
            </div>
        )
    }
}

export default withAuth(LeaveApproveContainer);