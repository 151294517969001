import React, { Component } from 'react'
import { withStyles, Grid, Card, CardHeader, Avatar, Typography, CardContent } from '@material-ui/core';
import { GetData } from '../../../api/service';
import { blue } from '@material-ui/core/colors';

const styles = theme => ({

})



class AdmissionDashBoard extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/admissionform/count`)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        let { classes } = this.props;
        return (
            <div>



                {this.state.isLoaded ? (

                    <Grid container spacing={24}>
                        {this.state.data.map((el, index) =>

                            <Grid item xs={12} sm={12} lg={2} md={2} key={index}>

                                <Card>
                                    <CardHeader
                                        style={{
                                            backgroundColor: blue[300],
                                            
                                        }}
                                        title={
                                            <Typography
                                                variant="h6"
                                                style={{color: 'white'}}
                                                >
                                                {el.title}
                                                </Typography>
                                        }
                                        
                                    />
                                    <CardContent style={{ textAlign: 'right' }}>
                                        <Typography
                                            variant="h6"
                                        >
                                            {el.value}
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Grid>

                        )}
                    </Grid>


                ) : null}







            </div>
        )
    }
}

export default withStyles(styles)(AdmissionDashBoard)