import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import Edit from '@material-ui/icons/Edit';



export default class AdmissionReceiptTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    getType = (type) => {
        if(type == 0){
            return 'Regular';
        }else if(type == 1){
            return 'Self-financing';
        }else if(type == 2){
            return 'Examination';
        }
        
    }


    render() {
        const columns = [
            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Receipt No",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Form No",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Name",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Amount",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Print",
                options: {
                    filter: false,
                    sort: false,
                }
            },

            {
                name: "Edit",
                options: {
                    filter: false,
                    sort: false,
                }
            }

        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },

        };
        

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.date,
                    el.receipt_no,
                    el.course,
                    el.stream,
                    el.form_id,
                    el.name,
                    el.amount_paid,
                    this.getType(el.type),
                    <Print onClick={this.props.onPrintClick.bind(this,el.id)} />,
                    <Edit onClick={this.props.onEditClick.bind(this,el)} />,
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Receipts"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
