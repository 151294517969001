import React, { Component } from 'react'
import { withStyles, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button, Divider, Chip } from '@material-ui/core';
import { GetData } from './../../../api/service';

const styles = theme => ({
    formControl: {
        width: '30%',
        marginTop: theme.spacing.unit * 2
    },
    button: {
        marginTop: theme.spacing.unit * 3,

    },
    chipDiv: {
        display: 'grid',
        gridTemplateColumns: 'repeat(10, 1fr)',
        gridColumnGap: '5px',
        gridRowGap: '5px'
    }
})

class Condition extends Component {
    state = {
        type: '',
        type_value: '',
        conditions: [],
        f_type: [],
        isLoading: true,
        r_data: [],
        f_value: []
    }

    __fetchInitials = () => {
        GetData(`/admissionform/getreportinitials`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    f_type: Object.keys(resp),
                    isLoading: false,
                    r_data: resp
                })
            })

    }

    componentDidMount() {
        this.__fetchInitials();
    }

    onSubmit = () => {
        

        if(this.state.type !== '' && this.state.type_value !== ''){
            const data = {
                type: this.state.type,
                type_value: this.state.type_value
            }

            let conditions = this.state.conditions;
            conditions.push(data);
            

            this.setState({
                conditions,
                type: '',
                type_value: ''
            }, () => {
                this.props.setTable(conditions)
            })
        }

        

    }

    onRemove = (index) => {
        let conditions = this.state.conditions;
        conditions.splice(index, 1);
        this.setState({
            conditions
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'type') {
            let r_data = this.state.r_data;
            let v = e.target.value;
            if (v !== '') {
                this.setState({
                    f_value: r_data[v]
                })
            } else {
                this.setState({
                    f_value: []
                })
            }

        }
    }

    render() {
        let { classes } = this.props;
        let i = [];
        let j = [];
        let k = [];
        if (!this.state.isLoading) {
            let f_type = this.state.f_type;
            if (f_type.length > 0) {
                i = f_type.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }
        }

        let f_value = this.state.f_value;
        if (f_value.length > 0) {
            j = f_value.map((el, index) =>
                <MenuItem key={index} value={el}>{el}</MenuItem>
            )
        }

        let conditions = this.state.conditions;
        if (conditions.length > 0) {
            k = conditions.map((el, index) =>
                <SearchTag
                    key={index}
                    type={el.type}
                    type_value={el.type_value}
                    onRemove={this.onRemove.bind(this, index)}
                />
            )
        }
        return (
            <div>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-simple">
                        Condition Type
                    </InputLabel>
                    <Select
                        name="type"
                        value={this.state.type}
                        onChange={this.onChange}
                        input={
                            <OutlinedInput labelWidth={200} name="type" />
                        }
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {i}

                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-simple">
                        Type Value
                    </InputLabel>
                    <Select
                        name="type_value"
                        value={this.state.type_value}
                        onChange={this.onChange}
                        input={
                            <OutlinedInput labelWidth={200} name="type_value" />
                        }
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {j}

                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.onSubmit}
                >
                    Add
                    </Button>


                <br />
                <br />
                <div className={classes.chipDiv}>
                    {k}
                </div>
                

            </div>
        )
    }
}

export default withStyles(styles)(Condition)

export const SearchTag = (props) => {
    return (
        <div>

            <Chip
                color="primary"
                label={`${props.type} : ${props.type_value}`}
                onDelete={props.onRemove}
            />


        </div>
    )
}