import React, { Component } from 'react'
import { withStyles, Grid, Card, CardHeader, Avatar, Typography, CardContent, Divider } from '@material-ui/core';
import CreditCard from '@material-ui/icons/CreditCard'
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';

const styles = theme => ({

})


class AccountsDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            sub_table:[],
            edit: false,
            edit_data: [],
            is_all_loaded: false
        }

    }

    __getData() {
        GetData(`/${this.props.apikey}/${this.props.username}/getaccountbalance`)
            .then((resp) => {
                //console.log(resp)
                GetData(`/${this.props.apikey}/${this.props.username}/getheadbalance`)
                .then((res) => {
                    console.log(res)
                    this.setState({
                        table_data: resp,
                        sub_table:res,
                        isLoading: false
                    })
                })
                
            })
    }

    componentDidMount() {
        this.__getData();
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Typography
                    variant="h5"
                    gutterBottom
                >
                    Bank Balances
          </Typography>
                <Grid container spacing={24}>
                    {this.state.table_data.map((el, index) =>
                        <Grid key={index} item xs={12} sm={12} lg={3} md={3}>

                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            <CreditCard />
                                        </Avatar>
                                    }
                                    title={
                                        <Typography>
                                            {el.bank_name}
                                        </Typography>
                                    }
                                    subheader={el.bank_account}
                                />
                                <CardContent style={{ textAlign: 'right' }}>
                                    <Typography
                                        variant="h6"
                                    >
                                        &#8377; {el.cr_bal}
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Grid>

                    )}
                </Grid>
                <br />
                <br />

                <Divider />
                <br />


                <Typography
                    variant="h5"
                    gutterBottom
                >
                    Accounts Head Balances
          </Typography>
                <Grid container spacing={24}>
                    {this.state.sub_table.map((el, index) =>
                        <Grid key={index} item xs={12} sm={12} lg={4} md={4}>

                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            <CreditCard />
                                        </Avatar>
                                    }
                                    title={
                                        <Typography
                                            variant="h6"
                                        >
                                            {el.head_name}
                                        </Typography>
                                    }
                                    
                                />
                                <CardContent style={{ textAlign: 'right' }}>
                                    <Typography
                                        variant="h6"
                                    >
                                        &#8377; {el.cr_bal}
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Grid>

                    )}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(AccountsDashboard)