import React, { Component } from 'react';
import './css/welcome.css';
import { GetData, PostData } from '../../api/service';
import TopLabel from '../DashBoard/TopLabel';


export default class Welcome extends Component {  
  render() {
    return (
      <div>
        <TopLabel {...this.props} />
      </div>
    )
  }
}
