import React, { Component } from 'react'
import { GetData, DeleteData } from '../../api/service';
import LeaveTable from './LeaveTable';
import LeaveModal from './LeaveModal';
import { withStyles } from '@material-ui/core';
import LeaveEdit from './LeaveEdit';
import LeaveEditModal from './LeaveEditModal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Notifications, { notify } from 'react-notify-toast';

const styles = theme => ({
    table: {
        display: 'block'
    }
})

class LeaveStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leaves: [],
            isLoading: true,
            view_data: [],
            view: false,
            edit: false,
            edit_data: []
        }
    }

    __initialFetch(){
        GetData(`/${this.props.username}/getemployeeleavesingle`)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    leaves: resp,
                    isLoading: false
                })
            })
    }

    componentDidMount(){
        this.__initialFetch();
    }

    actionClick(el){
        if(el.action < 2){
            this.setState({
                edit_data: el,
                edit: true
            })
        }
        
    }

    onViewClick(el){
        this.setState({
            view: true,
            view_data: el
        })
    }

    reloadList(){
        this.__initialFetch();
        this.setState({
            edit_data: [],
            edit: false
        })
    }

    handleDelete(id){
        this.setState({
            edit: false,
            edit_data: []
        })
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this,id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id){
        DeleteData(`/${this.props.apikey}/${id}/${this.props.username}/deleteEmployeeLeave`)
        .then((resp) => {
            notify.show(resp, 3000);
            return resp;
        }).then((resp) => {
            this.__initialFetch();
        })
    }
    
    render() {
        let {classes} = this.props;
        return (
            <div>
                <Notifications options={{ zIndex: 2000 }} />
                

                {this.state.isLoading ? null :<LeaveTable
                    table_data={this.state.leaves}
                    isLoading={this.state.isLoading}
                    actionClick={this.actionClick.bind(this)}
                    onViewClick={this.onViewClick.bind(this)}
                />}

                <LeaveModal
                    open={this.state.view}
                    handleClose={() => {
                        this.setState({
                            view: false,
                            view_data: []
                        })
                    }}
                    view_data={this.state.view_data}
                />


                {this.state.edit ? <LeaveEditModal
                    open={this.state.edit}
                    handleClose={() => {
                        this.setState({
                            edit: false,
                            edit_data: []
                        })
                    }}
                    edit_data={this.state.edit_data}
                    username={this.props.username} 
                    apikey={this.props.apikey}
                    reloadList={this.reloadList.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />: null}
                
      </div>
        )
    }
}

export default withStyles(styles)(LeaveStatus);