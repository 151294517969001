import React, { Component } from 'react'
import { withStyles, Modal, Typography, Grid, Divider, Button, FormControl, InputLabel, Select, MenuItem, LinearProgress, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import FileUpload from './../FileUpload/FileUpload'
import { PostData } from '../../api/service';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: '10%',
        left: '20%',
        [theme.breakpoints.down('sm')]: {
            left: 0,
            top: 0,
            width: '100vw'
        }
    },
    modalclass: {
        overflowY: 'scroll'
    },
    formControl:{
        width: '100%'
    },
    textStyle: {
        fontSize: 20,
        fontWeight: 400
    },
    messageField: {
        width: '100%'
    }
})

class ApproveFinal extends Component {
    state = {
        id: this.props.edit_data.id,
        action: '',
        return_comment: '',
        return_file: [],
        open: this.props.open
    };

    componentDidMount(){
        if(this.props.open){
            //console.log(this.props.edit_data)
            this.setState({
                action: this.props.edit_data.action,
                return_comment: this.props.edit_data.return_comment,
                return_file: this.props.edit_data.return_file,
            })
        }
        
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSetFile(data){
        this.setState({
            return_file: data
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let {id, action, return_comment, return_file} = this.state;

        const data = {
            id, action, return_comment, return_file
        }

        PostData(`/${this.state.p_apikey}/${id}/changeleavestatus`, data)
            .then((resp) => {
                
                this.props.setData(resp)

            })
    }

    goodDate(date) {
        let d = new Date(date);
        let month = parseFloat(d.getMonth() + 1);
        let dateFormat = d.getDate() + '-' + month + '-' + d.getFullYear();
        return dateFormat;
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                
                <Dialog
                    open={this.state.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                 
                >
                    <form onSubmit={this.onSubmit}>

                    <DialogTitle>
                        Leave Update
                    </DialogTitle>

                    <DialogContent>
                
                    <div>
                    
                        {/* <Typography variant="h6">
                            Leave Update 
            </Typography>

                        <Typography variant="subtitle1">
                            {this.props.edit_data.leave_type}, By <b>{this.props.edit_data.name} ({this.props.edit_data.dept_code})</b>
            </Typography>

                        <Typography variant="subtitle1">
                            From: <b>{this.props.edit_data.start_date}</b> To: <b>{this.props.edit_data.end_date}</b> <br />No of Days: <b>{this.props.edit_data.no_of_days}</b>
            </Typography>

                        <Typography variant="subtitle1">
                            Reason: <div dangerouslySetInnerHTML={{ __html: this.props.edit_data.message}}></div>
            </Typography>

                <br/>
                        
                        <Divider />

                            <br/> */}

                        
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <FormControl className={classes.formControl}>

                                    <InputLabel shrink className={classes.textStyle}>Action</InputLabel>
                                    <Select
                                        value={this.state.action}
                                        onChange={this.onChange}
                                        name="action"
                                        inputProps={{
                                            name: 'action',
                                            required: true
                                        }}

                                    >
                                        <MenuItem value="">
                                            <em>Choose Action</em>
                                        </MenuItem>
                                        <MenuItem value="0">Pending</MenuItem>
                                        <MenuItem value="1">Received</MenuItem>
                                        <MenuItem value="2">Granted</MenuItem>
                                        <MenuItem value="3">Rejected</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                
                                    <TextField
                                    variant="outlined"
                                        label="Reply or Comments"
                                        name="return_comment"
                                        className={classes.messageField}
                                        type="text"
                                        required
                                        multiline
                                        rows="6"
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            
                                            required: true,

                                        }}
                                        value={this.state.return_comment}
                                        onChange={this.onChange}
                                    />
                            </Grid>

                            <Grid item xs={12} lg={12} style={{ backgroundColor: '#F2F2F2' }}>

                                <Typography
                                    variant="body2"
                                    gutterBottom
                                >
                                    Upload File
                                </Typography>
                                <FileUpload
                                    dp={this.state.return_file}
                                    apikey={this.props.apikey}
                                    type="RETURNFILE"
                                    setDp={this.onSetFile.bind(this)}
                                />
                            </Grid>

                            
                        </Grid>
                      
                        
                    </div>
                    </DialogContent>
                    <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        type="button"
                                        onClick={this.props.handleClose}
                                    >
                                        Cancel
                                    </Button>
                              
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Final Submit
                                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
                
            </div>
        )
    }
}

export default withStyles(styles)(ApproveFinal);