import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, Grid, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, CardHeader } from '@material-ui/core';
import { GetData, PostData } from '../../../api/service';
import ViewTable from './ViewTable';
import Condition from './Condition';
import ReactToPrint from 'react-to-print';
import ViewTableShort from './ViewTableShort';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const styles = theme => ({
    formControl: {
        width: '100%'
    }
})

class AdmissionViewListShort extends Component {

    state = {
        course: '',
        stream: '',
        course_type: '',
        caste: '',
        data: [],
        isLoaded: false,

    }

    buttonRef = React.createRef();

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let course_type = '';

        if(this.state.course === 'TDC'){
            course_type = this.state.course_type
        }

        let d = {
            course: this.state.course,
            stream: this.state.stream,
            caste: this.state.caste
        }

        PostData(`/admissionform/meritlistshort`, d)
        .then((resp) => {
            //console.log(resp);
            this.setState({
                data: resp,
                isLoaded: true
            })
        })
    }

    setTable = (name, value) => {

        this.setState({
            [name]: value
        })
    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            title={`Shortlist Generator`}
                        />
                        <CardContent>

                            <Grid container spacing={16}>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Course
                                        </InputLabel>
                                        <Select
                                            name="course"
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="course" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            <MenuItem value="HS">HS</MenuItem>
                                            <MenuItem value="TDC">TDC</MenuItem>
                                            <MenuItem value="PG">PG</MenuItem>
                                            <MenuItem value="BSC-BIOTECHNOLOGY">BSC-BioTechnology</MenuItem>
                                            <MenuItem value="BCA">BCA</MenuItem>


                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3}>
                                    <FormControl required variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Stream
                        </InputLabel>
                                        <Select
                                            name="stream"
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="stream" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="ARTS">ARTS</MenuItem>
                                            <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                            <MenuItem value="COMMERCE">COMMERCE</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                

                                <Grid item lg={3}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-simple">
                                            Caste (Optional)
                    </InputLabel>
                                        <Select
                                            name="caste"
                                            value={this.state.caste}
                                            onChange={this.onChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="caste" />
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            <MenuItem value="GENERAL">GENERAL</MenuItem>
                                            <MenuItem value="ST">ST</MenuItem>
                                            <MenuItem value="ST(H)">ST(H)</MenuItem>
                                            <MenuItem value="ST(P)">ST(P)</MenuItem>
                                            <MenuItem value="SC">SC</MenuItem>
                                            <MenuItem value="OBC">OBC</MenuItem>
                                            <MenuItem value="MOBC">MOBC</MenuItem>


                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                                        </Button>
                        </CardActions>
                    </Card>
                </form>

                <br />

                


                {this.state.isLoaded ? 
                    (<Card>
                        <CardContent style={{textAlign: 'center'}}>
                            <ReactToPrint
                                trigger={() =>
                                    <Button
                                        variant="outlined"
                                        color="primary"

                                    >
                                        Print
                        </Button>
                                }
                                ref={el => this.buttonRef = el}
                                content={() => this.componentRef}
                                pageStyle={{ margin: '10px' }}
                                copyStyles={true}
                            />


                           

                            
                        </CardContent>
                        <CardContent>
                            <ViewTableShort 
                                ref={el => (this.componentRef = el)}
                            data={this.state.data}
                                {...this.state}
                            />
                        </CardContent>
                        
                    </Card>)
                    : null}
            </div>
        )
    }
}

export default withStyles(styles)(AdmissionViewListShort)