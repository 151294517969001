import React, { Component } from 'react'
import { withStyles, Typography, Card, CardContent, CardHeader, Button, Divider, Grid } from '@material-ui/core';
import { PostData } from '../../../api/service';
import ReactToPrint from 'react-to-print';

const styles = theme => ({
    card: {
        width: '21cm'
    }
})

class ReceiptPrint extends Component {
    state = {
        data: [],
        isLoaded: false,
        receipt_no: ''
    }

    __fetchReceiptData = (receipt_no) => {
        let d = {
            receipt_no: receipt_no
        }

        PostData(`/admission/getreceiptsbyreceiptno`, d)
            .then((resp) => {
                //console.log(resp);
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }

            })
    }

    componentDidMount() {
        this.setState({
            receipt_no: this.props.receipt_no
        }, () => this.__fetchReceiptData(this.props.receipt_no))
    }

    printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Card>
                    <CardHeader
                        title={`Print Receipt`}
                    />
                    <CardContent>

                        

                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '20px', }}
                            bodyClass={{ }}
                            copyStyles={true}

                        />

                        <Button
                            onClick={() => window.location.reload()}
                        >
                            GO BACK
                        </Button>

                        <br />
                        <br />

                        {this.state.isLoaded ? <AdmissionForm
                            ref={el => (this.componentRef = el)}
                            {...this.props}
                            {...this.state.data}
                            
                        /> : null}
                    </CardContent>
                </Card>

            
            </div>
        )
    }
}

export default withStyles(styles)(ReceiptPrint)

class AdmissionForm extends Component {

    showSubjects = (subjects) => {
        let i = '';
        if (Array.isArray(subjects) && subjects.length > 0) {
            i = subjects.map((el, index) =>
                el.label
            )
        }

        return i.join(',');
    }
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        let {classes} = this.props;
        return (
            <div className={classes.card}>
                <style type="text/css">
                    {"@media print{@page {size: landscape, max-height:100%; max-width:100%}}"}
                </style>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={require('./../../../assets/logo-wide.png')}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pandu College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O. - Pandu College, Guwahati - 781 012
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {this.props.receipt_type}
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Receipt No. : <b>{this.props.type === 1 ? 'S' : null}{this.props.receipt_no}</b> <br />

                                    Date : <b>{this.props.date}</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Divider />
                <br />



                {this.props.is_other == 0 ? (<Grid container spacing={2}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="30%">
                                        <Typography

                                        >
                                            Form No : <b>{this.props.form_id}</b>
                                        </Typography>
                                    </td>


                                    <td width="30%">
                                        <Typography
                                            variant=""
                                        >
                                            Class:  <b>{this.props.course} {this.props.stream} {this.props.course_type}</b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        <Typography
                                            variant=""
                                        >
                                            {this.props.course == 'HS' ? 'Year' : 'Semester'}:  <b>
                                                {this.props.semester == '3' && this.props.course != 'PG' ? '3rd & 4th Semester' : null}
                                                {this.props.semester == '5' && this.props.course != 'PG' ? '5th & 6th Semester' : null}
                                                {this.props.semester == '2' && this.props.course != 'PG' ? '2nd Year' : null}
                                                {this.props.semester == '1' && this.props.course == 'HS' ? '1st Year' : null}
                                                {this.props.semester == '1' && this.props.course == 'TDC' ? '1st Semester' : null}
                                                {this.props.semester == '1' && this.props.course == 'BSC-BIOTECHNOLOGY' ? '1st Semester' : null}
                                                {this.props.semester == '1' && this.props.course == 'BCA' ? '1st Semester' : null}
                                                {this.props.semester == '1' && this.props.course == 'PG' ? '1st Semester' : null}
                                                {this.props.semester == '2' && this.props.course == 'PG' ? '2nd Semester' : null}
                                                {this.props.semester == '3' && this.props.course == 'PG' ? '3rd Semester' : null}
                                                {this.props.semester == '4' && this.props.course == 'PG' ? '4th Semester' : null}
                                            </b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        <Typography
                                            variant=""
                                        >
                                            Roll No:  <b>{this.props.roll_no}</b>
                                        </Typography>
                                    </td>

                                </tr>

                                <tr>
                                    <td width="30%">
                                        <Typography

                                        >
                                            Student's Name : <b>{this.props.name}</b>
                                        </Typography>
                                    </td>

                                    <td width="70%" colSpan="3">
                                        <Typography
                                            variant=""
                                        >
                                            Subjects:  <b>{this.showSubjects(this.props.subjects)}</b>
                                        </Typography>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </Grid>



                </Grid>): null}



                {this.props.is_other == 1 ? (<Grid container spacing={2}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table width="100%">
                            <tbody>
                                

                                <tr>
                                    <td width="50%">
                                        <Typography

                                        >
                                            Name : <b>{this.props.name}</b>
                                        </Typography>
                                    </td>

                                    <td width="50%">
                                        <Typography
                                            variant=""
                                        >
                                            Remarks:  <b>{this.props.remarks}</b>
                                        </Typography>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </Grid>



                </Grid>) : null}

                <Divider />
                <br />



                <table width="100%" className="table">
                    <thead>
                        <tr>
                            <td width="10%">
                                <Typography>
                                    #
                                </Typography>
                            </td>

                            <td width="60%">
                                <Typography>
                                    Fees Head
                                </Typography>
                            </td>
                            <td width="30%" align="right">
                                <Typography>
                                    Fees Amount
                                </Typography>
                            </td>
                        </tr>
                    </thead>

                    {Array.isArray(this.props.fees) && this.props.fees.length > 0 ? (
                        <tbody>



                            {Array.isArray(this.props.fees) && this.props.fees.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <Typography

                                            >
                                                {el.sm_head}
                                            </Typography>
                                        </td>
                                        <td align="right">
                                            <Typography

                                            >
                                                {el.sm_amount}
                                            </Typography>
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Amount Paid
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography

                                    >
                                        {this.props.amount_paid}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Mode of Payment
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography

                                    >
                                        {this.props.mop}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>

                    ) : null}

                </table>

                <br />
                <br />
                <br />

                {this.props.is_free_admission == '1' && this.props.form_id != null ? (
                    <div>
                        <Typography
                            variant="body2"
                        >
                            ( Free Admission vide DHE/PEN/Misc/171/2019/36 )
                    </Typography>
                    </div>
                ) : null}

                <div align="right">

                    <Typography
                        variant="body2"
                    >
                        Collecting Officer
                    </Typography>

                </div>








            </div>
        )
    }
}


