import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import CourseAdd from './RoutineAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css



export default class AccountSubHeads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            sub_table:[],
            edit: false,
            edit_data: [],
            is_all_loaded: false
        }

    }

    __getData() {
        GetData(`/getaccountssubhead`)
            .then((resp) => {
                //console.log(resp)
                GetData(`/getsubheadbyhead`)
                .then((res) => {
                    
                    this.setState({
                        table_data: res,
                        sub_table:res,
                        isLoading: false
                    })
                })
                
            })
    }

    componentDidMount() {
        this.__getData();
    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }

    setData(data) {
        //send the data to the server

        PostData(`/${this.props.apikey}/${this.props.username}/addaccountssubhead`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp,
                    edit: false
                })
                this.__getData();
            })
    }

    actionClick(el) {
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }

    esetData(data) {
        //send the data to the server

        PutData(`/${this.props.apikey}/${this.props.username}/${data.id}/updateaccountssubhead`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${this.props.username}/${id}/deleteaccountssubhead`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onButtonClick = (id, head_name) => {
        let d = {
            ...id,
            head_name,
            account_type: 'subhead',
            
        }
        this.props.history.push('/accounts/view', d);
    }


    render() {
        return (
            <div>
                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}
                {this.state.isLoading ? <LinearProgress color="secondary" /> : null}


                <CourseAdd
                    show={this.state.show}
                    handleClose={this.onAddClick.bind(this)}
                    setData={this.setData.bind(this)}
                    edit={this.state.edit}
                    edit_data={this.state.edit_data}
                    esetData={this.esetData.bind(this)}
                    onDeleteClick={this.onDeleteClick.bind(this)}
                    is_all_loaded={this.state.is_all_loaded}
                    
                />



                {!this.state.isLoading ? <CourseTable
                    table_data={this.state.table_data}
                    isLoading={this.state.isLoading}
                    onAddClick={this.onAddClick.bind(this)}
                    onButtonClick={this.onButtonClick.bind(this)}
                    actionClick={this.actionClick.bind(this)} /> : null}
            </div>
        )
    }
}
