import React, { Component } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { withStyles, Card, CardContent, Typography } from '@material-ui/core';
import Report from '@material-ui/icons/Report';
import { lightBlue, red } from '@material-ui/core/colors';
import AccountsDashBoard from './View/AccountsDashBoard';
import AccountHeads from './AccountHeads/AccountHeads';
import CashBook from './CashBook/CashBook';
import CashBookView from './CashBookView/CashBookView';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import OpeningBalance from './OpeningBalance/OpeningBalance';
import AccountSubHeads from './AccountSubHeads/AccountSubHeads';
import Reports from './Reports/Reports';

const styles = theme => ({
    smallItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    ava: {
        width: 80,
        height: 80,
        backgroundColor: lightBlue[300],
        marginBottom: '8px',
    },
    icon: {
        fontSize: 35
    },
    noAccessDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: red[700]
    },
    noAccessIcon: {
        color: red[300]
    },
    navBarSmall: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 14,
        marginTop: '10vh',
        marginLeft: 240,
        backgroundColor: 'rgba(0,0,0,0.8)',
        height: '7vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: '7vh',
            height: '10vh',
            overflowX: 'scroll',
        }
    },
    navBarItem: {
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&:hover': {
            color: 'blue'
        },
        borderRight: 'solid thin white',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    container:{
        paddingTop: '8vh'
    }
});


const newNavs = [
    {
        title: 'Dashboard',
        path: '/accounts'
    },
    {
        title: 'Bank Accounts',
        path: '/accounts/payments'
    },
    {
        title: 'Accounts Head',
        path: '/accounts/accountheads'
    },
    {
        title: 'Accounts Sub Head',
        path: '/accounts/accountsubheads'
    },
    {
        title: 'Op Balance (Head)',
        path: '/accounts/opbalancehead'
    },
    {
        title: 'Op Balance (Accounts)',
        path: '/accounts/opbalanceaccounts'
    },
    {
        title: 'Cash Book',
        path: '/accounts/cashbook'
    },
    {
        title: 'Reports',
        path: '/accounts/reports'
    }
]

class AccountsLanding extends Component {
    constructor(props) {
        super(props);

        let stat = false;

        let user = JSON.parse(localStorage.getItem('user'));
        let access = user.user.access;
        if (Array.isArray(access) && access.length > 0) {
            access.map((el, index) => {
                if (el.access_field === 'ACC') {
                    stat = true;
                }
            })
        }

        this.state = {
            access_egov: stat
        }
    }

    render() {
        let { classes } = this.props;
        if (this.state.access_egov) {
            return (
                <div>
                    

               
                    <EGovNav {...this.props} />
                
                    <div className={classes.container}>
                        <Switch>
                            <Route exact path="/accounts"
                                render={() => <AccountsDashBoard
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/accountheads"
                                render={() => <AccountHeads
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/accountsubheads"
                                render={() => <AccountSubHeads
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/payments"
                                render={() => <PaymentOptions
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/opbalancehead"
                                render={() => <OpeningBalance
                                    link={`getheadbalance`}
                                    title={`Current Balance (Head)`}
                                    type={`head`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/opbalanceaccounts"
                                render={() => <OpeningBalance
                                    link={`getaccountbalance`}
                                    title={`Current Balance (Accounts)`}
                                    type={`account`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/cashbook"
                                render={() => <CashBook
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/reports"
                                render={() => <Reports
                                    link={`getreports`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                            <Route exact path="/accounts/view"
                                render={() => <CashBookView
                                    link={`getdocuments`}
                                    title={`Inbox`}
                                    history={this.props.history}
                                    username={this.props.username}
                                    name={this.props.name}
                                    apikey={this.props.apikey}
                                />} />

                        </Switch>
                    </div>

                    

                    <br />

                </div>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardContent className={classes.noAccessDiv}>
                            <Report className={classes.noAccessIcon} />

                            <Typography
                                variant="body2"
                                color="inherit"
                            >
                                Sorry !! You don't have access to this feature
                        </Typography>
                        </CardContent>
                    </Card>
                </div>
            )
        }

    }
}

export default withStyles(styles)(AccountsLanding);


export const EGovNav = (props) => {
    let { classes } = props;
    return (

        <div className={classes.navBarSmall}>
            {newNavs.map((el,index) => 
                
                <Link key={index} to={el.path}>
                    <div className={classes.navBarItem}>
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            {el.title}
                </Typography>
                    </div>
                </Link>
                
                
                )}
        </div>

    )
}