import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom';
import HomePage from './Containers/HomePage';
import LeaveContainer from './Containers/LeaveContainer';

export default class ProtectedRoutes extends Component {
  render() {
    return (
      <div>
        <Route exact path="/leave/apply" component={LeaveContainer} />
        <Route exact path="/leave/status" component={LeaveContainer} />
        <Route exact path="/leave/approve/:action" component={LeaveContainer} />
      </div>
    )
  }
}
