import React, { Component } from 'react'
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from '@material-ui/core';
import { GetData } from './../../api/service';

const styles = theme => ({
    colorRed: {
        color: 'red'
    }
})

class ExtraClassModal extends Component {

    state = {
        term_name: '',
        date: '',
        term_list: [1, 2, 3, 4, 5, 6],
        isLoaded: false,
        streams: [],
        courses: [],
        sessions: [],
        subjects: [],
        session: '',
        course: '',
        course_type: '',
        stream: '',
        semester: '',
        section: '',
        sub_code: '',
        day: '',
        errors: [],
        dept_code: '',
        period_number: '',
        departments: []
    }

    componentDidMount() {
        GetData('/getstream')
            .then((resp) => {
                this.setState({
                    streams: resp
                })
            })
            .then(() => {
                GetData('/getcourse')
                    .then((resp) => {
                        this.setState({
                            courses: resp
                        })
                    })
                    .then(() => {
                        GetData(`/${this.props.username}/getsubjectbydeptcode`)
                            .then((resp) => {
                                this.setState({
                                    subjects: resp,

                                })
                            }).then(() => {
                                GetData('/getdepartment')
                                    .then((resp) => {

                                        this.setState({
                                            departments: resp,
                                            isLoaded: true
                                        })
                                    })
                            })
                    })
            })
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course') {
            let term_name = ""; let term_list = [];
            let course = this.state.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }
        }
    }

    handleValidation = () => {
        let { section, date, course, stream, day, sub_code, semester, period_number, errors } = this.state;
        let isValid = true;





        if (course === '') {
            isValid = false;
            errors['course'] = 'Cannot be empty';
        } else {
            errors['course'] = '';
        }

        if (stream === '') {
            isValid = false;
            errors['stream'] = 'Cannot be empty';
        }
        else {
            errors['stream'] = '';
        }

        if (day === '') {
            isValid = false;
            errors['day'] = 'Cannot be empty';
        } else {
            errors['day'] = '';
        }

        if (sub_code === '') {
            isValid = false;
            errors['sub_code'] = 'Cannot be empty';
        } else {
            errors['sub_code'] = '';
        }

        if (semester === '') {
            isValid = false;
            errors['semester'] = 'Cannot be empty';
        } else {
            errors['semester'] = '';
        }

        if (period_number === '') {
            isValid = false;
            errors['period_number'] = 'Cannot be empty';
        } else {
            errors['period_number'] = '';
        }



        this.setState({
            errors
        })

        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            //submit only validated data
            let { section, date, course, stream, day, sub_code, semester, period_number, course_type } = this.state;
            let data = {
                date: date,
                course: course,
                day: day,
                semester: semester,
                stream: stream,
                sub_code: sub_code,
                period_number: period_number,
                section: section,
                course_type: course_type
            }

            this.props.history.push('/takeattendance', data);
        }
    }


    render() {
        let { classes } = this.props;
        let stream = [];
        let course = [];
        let session = [];
        let subject = [];
        let semester = [];
        let department = [];
        if (this.state.isLoaded) {
            let i = this.state.courses;
            if (Array.isArray(i) && i.length > 0) {
                course = i.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let j = this.state.streams;
            if (Array.isArray(j) && j.length > 0) {
                stream = j.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let k = this.state.term_list;
            if (Array.isArray(k) && k.length > 0) {
                semester = k.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }

            let l = this.state.sessions;
            if (Array.isArray(l) && l.length > 0) {
                session = l.map((el, index) =>
                    <MenuItem key={index} value={el.session_name}>{el.session_name}</MenuItem>
                )
            }

            let m = this.state.subjects;
            if (Array.isArray(m) && m.length > 0) {
                subject = m.map((el, index) =>
                    <MenuItem key={index} value={el.sub_code}>{el.sub_code}</MenuItem>
                )
            }

            let d = this.state.departments;
            if (Array.isArray(d) && d.length > 0) {
                department = d.map((el, index) =>
                    <MenuItem key={index} value={el.dept_code}>{el.dept_name}</MenuItem>
                )
            }

        }
        return (
            <div>

                <Dialog
                    open={true}
                    onClose={this.handleClose}
                    scroll="paper"

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Add Previous Attendance"}</DialogTitle>
                    <form onSubmit={this.onSubmit}>
                        <DialogContent >

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <label>Date</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="date"
                                        value={this.state.date}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {course}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            name="stream"
                                            inputProps={{
                                                name: 'stream'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {stream}
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['stream']}</FormHelperText>
                                    </FormControl>

                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Department</InputLabel>
                                        <Select
                                            value={this.state.dept_code}
                                            onChange={this.onChange}
                                            name="dept_code"
                                            inputProps={{
                                                name: 'dept_code'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {department}
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['dept_code']}</FormHelperText>
                                    </FormControl>
                                </Grid> */}

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Subject Code</InputLabel>
                                        <Select
                                            value={this.state.sub_code}
                                            onChange={this.onChange}
                                            name="sub_code"
                                            inputProps={{
                                                name: 'sub_code'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {subject}
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['sub_code']}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HC">HC</MenuItem>
                                            <MenuItem value="RC">RC</MenuItem>
                                            <MenuItem value="HG">HG</MenuItem>
                                            <MenuItem value="RC+HG">RC+HG</MenuItem>
                                        </Select>
                                    </FormControl>


                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {semester}
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['semester']}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Section (if any)</InputLabel>
                                        <Select
                                            value={this.state.section}
                                            onChange={this.onChange}
                                            name="section"
                                            inputProps={{
                                                name: 'section'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="A">A</MenuItem>
                                            <MenuItem value="B">B</MenuItem>
                                            <MenuItem value="C">C</MenuItem>
                                            <MenuItem value="D">D</MenuItem>
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['day']}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Select Day</InputLabel>
                                        <Select
                                            value={this.state.day}
                                            onChange={this.onChange}
                                            name="day"
                                            inputProps={{
                                                name: 'day'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Mon">Monday</MenuItem>
                                            <MenuItem value="Tue">Tuesday</MenuItem>
                                            <MenuItem value="Wed">Wednesday</MenuItem>
                                            <MenuItem value="Thu">Thursday</MenuItem>
                                            <MenuItem value="Fri">Friday</MenuItem>
                                            <MenuItem value="Sat">Saturday</MenuItem>
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['day']}</FormHelperText>
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink className={classes.textStyle}>Period Number</InputLabel>
                                        <Select
                                            value={this.state.period_number}
                                            onChange={this.onChange}
                                            name="period_number"
                                            inputProps={{
                                                name: 'period_number'
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="1">1st</MenuItem>
                                            <MenuItem value="2">2nd</MenuItem>
                                            <MenuItem value="3">3rd</MenuItem>
                                            <MenuItem value="4">4th</MenuItem>
                                            <MenuItem value="5">5th</MenuItem>
                                            <MenuItem value="6">6th</MenuItem>
                                            <MenuItem value="7">7th</MenuItem>
                                            <MenuItem value="8">8th</MenuItem>
                                            <MenuItem value="9">9th</MenuItem>
                                        </Select>
                                        <FormHelperText className={classes.colorRed}>{this.state.errors['period_number']}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>








                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </div>
        )
    }
}

export default withStyles(styles)(ExtraClassModal)